// src/store/voiceSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchBackendData, clearAllData } from "./fetchBackendData";

const initialState = {
  selectedVoice: {},
};

const voiceSlice = createSlice({
  name: "selectedVoice",
  initialState,
  reducers: {
    saveSelectedVoice: (state, action) => {
      state.selectedVoice = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.selectedVoice = action.payload?.data.selectedVoice || [];
      })
      .addCase(clearAllData.fulfilled, (state) => {
        return { ...initialState };
      });
  },
});

export const { saveSelectedVoice } = voiceSlice.actions;
export default voiceSlice.reducer;
