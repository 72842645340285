import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { clearAllData, fetchBackendData } from "../store/fetchBackendData";
import { loginFamily } from "../store/authSlice";
import { fetchFamilyMembers } from "../store/FamilySlice";
import DOMPurify from "dompurify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import logger from "../utils/logger";

const codeRegex = /^[a-zA-Z0-9]{6}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function FamilyLogin({ open, onClose, onFamilySuccess }) {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const { csrfToken } = useSelector((state) => state.auth);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handlePostLogin = async () => {
      if (!userName) {
        logger.error("User name is missing in the login response.");
        return;
      }

      try {
        const clearDataResult = await dispatch(clearAllData());
        if (!clearAllData.fulfilled.match(clearDataResult)) {
          logger.warn("Failed to clear data");
          return;
        }

        if (csrfToken) {
          try {
            const resultAction = await dispatch(fetchBackendData());
            await dispatch(fetchFamilyMembers());
            logger.debug("Backend data fetched:", resultAction);
            logger.info("Family backend and family member dispatch successful");
          } catch (error) {
            logger.error("Fetch backend data error:", error);
          }
        } else {
          logger.warn("Login failed, token doesn't exist");
        }

        if (userName && csrfToken) {
          onFamilySuccess();
          onClose();
        } else {
          logger.error("User name or token is missing in the login response");
          throw new Error("User name is missing in the login response");
        }
      } catch (error) {
        logger.error("Failed to clear data or fetch backend data:", error);
      }
    };

    if (authStatus === "succeeded") {
      handlePostLogin();
    }
  }, [authStatus, csrfToken, userName, onClose]);

  const handleLogin = async () => {
    logger.debug("Received Code:", code);
    try {
      const sanitizedCode = code.trim();
      if (!codeRegex.test(sanitizedCode)) {
        setError("Invalid 6-digit code.");
        return;
      }

      const sanitizedEmail = DOMPurify.sanitize(email);
      if (!emailRegex.test(sanitizedEmail)) {
        setError("Invalid email format.");
        return;
      }

      if (!executeRecaptcha) {
        logger.error("Recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("login");

      if (!token) {
        alert("Please complete the reCAPTCHA.");
        return;
      }

      try {
        const result = await dispatch(
          loginFamily({
            code: sanitizedCode,
            email: sanitizedEmail,
            captchaToken: token,
          })
        );
        if (loginFamily.fulfilled.match(result)) {
          logger.info("Login successful. Updating state.");
          const fetchedName = result.payload.name;
          logger.debug("Fetched name: ", fetchedName);
          setUserName(fetchedName);
        } else {
          logger.error("Failed to log in: ", result.error.message);
        }
      } catch (error) {
        logger.error("Failed to log in:", error);
        setError("Failed to log in");
      }
    } catch (error) {
      logger.error("Failed to log in:", error);
      setError("Failed to log in");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Family Login
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontFamily: "Verdana, sans-serif" }}>
          Please enter your unique 6-digit code and email address to log in.
        </DialogContentText>
        {authError && (
          <DialogContentText
            style={{ color: "red", fontFamily: "Verdana, sans-serif" }}
          >
            {authError}
          </DialogContentText>
        )}
        <TextField
          margin="dense"
          label="Email Address"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        <TextField
          autoFocus
          margin="dense"
          label="6 Digit Code"
          type="text"
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        {error && (
          <Typography
            style={{ color: "red", fontFamily: "Verdana, sans-serif" }}
          >
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleLogin}
          color="primary"
          disabled={authStatus === "loading"}
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FamilyLogin;
