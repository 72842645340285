import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../store/authSlice";
import { clearAllData, fetchBackendData } from "../store/fetchBackendData";
import { useNavigate } from "react-router-dom";
import { fetchFamilyMembers } from "../store/FamilySlice";
import safeRegex from "safe-regex";
import DOMPurify from "dompurify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import logger from "../utils/logger";
import PropTypes from "prop-types";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function Login({ open, onClose, onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState(null);
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.auth.status);
  const authError = useSelector((state) => state.auth.error);
  const navigate = useNavigate();
  const { csrfToken } = useSelector((state) => state.auth);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    const handlePostLogin = async () => {
      try {
        const clearDataResult = await dispatch(clearAllData());
        if (!clearAllData.fulfilled.match(clearDataResult)) {
          logger.debug(
            "Failed to clear data: " + JSON.stringify(clearDataResult.payload)
          );
          return;
        }
        logger.debug("Prior to fetching backend data, csrfToken: ", csrfToken);
        if (csrfToken) {
          try {
            await dispatch(fetchBackendData());

            await dispatch(fetchFamilyMembers());
            logger.info(
              "Backend data and family members fetched successfully."
            );
          } catch (error) {
            logger.error("Fetch backend data error:", error);
          }

          if (userName && csrfToken) {
            onLoginSuccess();
            onClose();
          } else {
            logger.error("User name is missing in the login response");
            logger.debug("User name:", userName);
          }
        } else {
          logger.error("Login failed, token doesn't exist");
        }
      } catch (error) {
        logger.error("Failed to clear data or fetch backend data:", error);
      }
    };

    if (authStatus === "succeeded" && csrfToken) {
      handlePostLogin();
    }
  }, [authStatus, csrfToken, user, onClose]);

  const handleLogin = async () => {
    try {
      const sanitizedEmail = DOMPurify.sanitize(email);
      if (!safeRegex(emailRegex) || !emailRegex.test(sanitizedEmail)) {
        alert("Invalid email format");
        return;
      }

      const sanitizedPassword = DOMPurify.sanitize(password);

      logger.debug(
        `Dispatching loginUser with email: ${sanitizedEmail}, password: ${sanitizedPassword}`
      );

      if (!executeRecaptcha) {
        logger.error("Recaptcha not yet available");
        return;
      }

      const token = await executeRecaptcha("login");

      if (!token) {
        alert("Please complete the reCAPTCHA.");
        return;
      }

      const result = await dispatch(
        loginUser({
          email: sanitizedEmail,
          password: sanitizedPassword,
          captchaToken: token,
        })
      );

      if (loginUser.fulfilled.match(result)) {
        logger.info("Login successful. Updating state.");
        const fetchedName = result.payload.name;
        logger.debug("Fetched name: ", fetchedName);
        setUserName(fetchedName);
      } else {
        logger.error("Failed to log in: ", result.error.message);
      }
    } catch (error) {
      logger.error("Failed to log in:", error);
    }
  };

  const handleForgotPassword = () => {
    navigate("/reset-password");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Login
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ fontFamily: "Verdana, sans-serif" }}>
          Please enter your login credentials.
        </DialogContentText>
        {authError && (
          <DialogContentText
            style={{ color: "red", ontFamily: "Verdana, sans-serif" }}
          >
            {authError}
          </DialogContentText>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="email"
          fullWidth
          value={email}
          onChange={(e) => setEmail(DOMPurify.sanitize(e.target.value))}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
        <TextField
          margin="dense"
          label="Password"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(DOMPurify.sanitize(e.target.value))}
          style={{ fontFamily: "Verdana, sans-serif" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleLogin}
          color="primary"
          disabled={authStatus === "loading"}
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Login
        </Button>
        <Button
          onClick={handleForgotPassword}
          color="secondary"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Forgot Password?
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Login.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
};

export default Login;
