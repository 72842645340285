import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "./store";
import NavigationDrawer from "./NavigationDrawer";
import FamilyNavigationDrawer from "./FamilyNavigationDrawer";
import Welcome from "./components/Welcome";
import Profile from "./components/Profile";
import EditProfile from "./components/EditProfile";
import ContactUs from "./components/ContactUs";
import MyDocuments from "./components/MyDocuments";
import FamilyDocuments from "./components/Family/FamilyDocuments";
import Login from "./components/Login";
import Register from "./components/Register";
import FamilyLogin from "./components/FamilyLogin";
import AddFamily from "./components/AddFamily";
import EditFamily from "./components/EditFamily";
import ResetPass from "./components/ResetPass";
import { logout } from "./store/authSlice";
import { logoutFamily } from "./store/FamilySlice";
import ProfilePlaceholder from "./Img/ProfilePlaceholder.jpg";
import { clearAllData } from "./store/fetchBackendData";
import axiosInstance from "./components/axiosConfig";
import { checkPayPalPaymentStatus } from "./store/stripeSlice";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import FrequentlyAskedQuestions from "./components/FrequentlyAskedQuestions";
import Checkout from "./components/Checkout";
import UpdatePayment from "./components/UpdatePayment";
import FamilyProfile from "./components/Family/FamilyProfile";
import FamilyEditProfile from "./components/Family/FamilyEditProfile";
import logger from "./utils/logger";
import NewPageForm from "./components/NewPageForm";
import DynamicStoriesPage from "./components/DynamicStoriesPage";
import DynamicVideosPage from "./components/DynamicVideosPage";
import DynamicPhotosPage from "./components/DynamicPhotosPage";
import FamilyDynamicStories from "./components/Family/FamilyDynamicStories";
import FamilyDynamicPhotos from "./components/Family/FamilyDynamicPhotos";
import FamilyDynamicVideos from "./components/Family/FamilyDynamicVideos";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SecurityPolicy from "./components/SecurityPolicy";
import AboutUs from "./components/AboutUs";
import TermsOfUse from "./components/TermsOfUse";
import Affiliate from "./components/Affiliate";
import Blog from "./components/Blog";

function App() {
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  if (!recaptchaKey) {
    logger.error("REACT_APP_RECAPTCHA_SITE_KEY not found in environment");
  }

  return (
    <Provider store={store}>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
        <Router>
          <AppContentWithRecaptcha />
        </Router>
      </GoogleReCaptchaProvider>
    </Provider>
  );
}

const AppContentWithRecaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  React.useEffect(() => {
    if (executeRecaptcha) {
      logger.info("reCAPTCHA is ready"); // Should now log reliably
    }
  }, [executeRecaptcha]);

  return <AppContent />;
};

function AppContent() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isPaid = useSelector((state) => state.stripe.isPaid);
  const [loggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [resetPassOpen, setResetPassOpen] = useState(false);
  const [familyOpen, setFamilyOpen] = useState(false);
  const [familyLogedIn, setFamilyLogedIn] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const navigate = useNavigate();
  const csrfToken = useSelector((state) => state.auth.csrfToken);
  const [profileImage, setProfileImage] = useState(null);
  const famliyNameFetched = useSelector(
    (state) => state.auth.famliyNameFetched
  );
  const pageConfigs = useSelector((state) => state.pageConfig.pageConfig || []);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const isMobile = isMobileDevice();

  useEffect(() => {
    const checkPayment = async () => {
      if (!familyLogedIn && user.id) {
        if (!isPaid) {
          setCheckoutOpen(true);
          logger.info("User is not paid.");
        } else {
          logger.info("User has already paid.");
        }
      } else if (familyLogedIn && user.id) {
        logger.info("Family member logging in paid.");
      } else {
        logger.warn("Home user not found");
      }
    };
    checkPayment();
  }, [user, isPaid, familyLogedIn]);

  useEffect(() => {
    if (isPaid) {
      setCheckoutOpen(false);
      logger.info("Checkout closed as payment is complete.");
    }
  }, [isPaid]);

  useEffect(() => {
    if (user) {
      if (checkPayPalPaymentStatus.status !== "loading") {
        dispatch(checkPayPalPaymentStatus());
      }
    }
  }, [user, isPaid]);

  useEffect(() => {
    if (user && isPaid) {
      getProfilePhoto();
    }
  }, [user, isPaid]);

  useEffect(() => {
    logger.debug(`PageConfig loaded: ${JSON.stringify(pageConfigs)}`);
  }, [pageConfigs]);

  useEffect(() => {
    logger.info("Profile photo refreshed");
  }, [user?.profileImage]);

  useEffect(() => {
    if (famliyNameFetched) {
      logger.info("Family member is logged in");
    }
  }, [famliyNameFetched]);

  useEffect(() => {
    const getAffiliateIdFromURL = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    const affiliateId = getAffiliateIdFromURL("affiliate_id");
    if (affiliateId) {
      document.cookie = `affiliate_id=${affiliateId}; path=/; max-age=${
        30 * 24 * 60 * 60
      }`;
      logger.debug("Affiliate ID captured globally:", affiliateId);
    }
  }, []);

  const getProfilePhoto = async () => {
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com.au/lifeauth/profile-get",
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const result = response.data[0];
        const base64ImageUrl = `data:${result.contentType};base64,${result.base64}`;
        setProfileImage(base64ImageUrl);
      } else {
        logger.warn("Failed to get image", response.data);
      }
    } catch (error) {
      logger.warn("Error fetching profile photo:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFamilyMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFamilyMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setLoggedIn(false);
    setFamilyLogedIn(false);
    try {
      dispatch(logout());
      dispatch(logoutFamily());
      dispatch(clearAllData());
    } catch (error) {
      logger.error("Error during logout:", error);
    }
    setProfileImage(null);
    handleMenuClose();
    navigate("/");
    logger.info("User logged out");
  };

  const handleLoginClick = () => {
    setLoginOpen(true);
  };

  const handleRegisterClick = () => {
    setRegisterOpen(true);
  };

  const handleFamilyClick = () => {
    setFamilyOpen(true);
  };

  const handleResetPassClose = () => {
    setResetPassOpen(false);
  };

  const handleLoginSuccess = () => {
    setLoggedIn(true);
    setLoginOpen(false);
  };

  const handleRegisterSuccess = () => {
    setRegisterOpen(false);
    setLoggedIn(true);
  };

  const handleCheckoutSuccess = async () => {
    try {
      if (isPaid) {
        setCheckoutOpen(false);
        navigate("/");
        alert("Payment plan successful!");
      } else {
        navigate("/");
      }
    } catch (error) {
      logger.error("Error during payment:", error);
      alert("Error during payment. Please try again.");
    }
  };

  const handleCheckoutFailure = (errorMessage) => {
    setCheckoutOpen(false);
    setLoggedIn(false);
    logger.error(`Checkout failed: ${errorMessage}`);
    navigate("/");
  };

  const handleCheckoutClose = () => {
    setLoggedIn(false);
    setCheckoutOpen(false);
  };

  const handleFamilySuccess = () => {
    setLoggedIn(true);
    setFamilyLogedIn(true);
    setFamilyOpen(false);
  };

  const handleFAQClose = () => {
    navigate("/");
  };

  const handlePrivacyPolicyClose = () => {
    navigate("/");
  };

  const handleSecurityPolicyClose = () => {
    navigate("/");
  };

  const handleAboutUsClose = () => {
    navigate("/");
  };

  const handleTermsClose = () => {
    navigate("/");
  };

  const handleAffiliateClose = () => {
    navigate("/");
  };

  const handleBlogClose = () => {
    navigate("/");
  };

  return (
    <div className="App">
      <AppBar
        style={{
          background: "linear-gradient(to right, #3F704D, #1F3B24)",
          height: 70,
        }}
        position="static"
      >
        <Toolbar>
          {loggedIn ? (
            <>
              {familyLogedIn ? (
                <FamilyNavigationDrawer />
              ) : (
                <NavigationDrawer />
              )}
              <Typography
                variant="h5"
                style={{
                  marginLeft: 50,
                  fontFamily: "'Days One', sans-serif",
                  fontWeight: "bold",
                  fontStyle: "italic",
                }}
              >
                Memory Diaries
              </Typography>
              <Button
                color="inherit"
                onClick={
                  familyLogedIn ? handleFamilyMenuClick : handleMenuClick
                }
                style={{ marginLeft: "auto" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginRight: "10%",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "'Architects Daughter', cursive",
                      marginRight: "20px",
                      fontSize: 18,
                      whiteSpace: "nowrap",
                      fontStyle: "italic",
                    }}
                  >
                    {familyLogedIn
                      ? `${user?.familyName?.name || "Now"} Viewing ${
                          user?.name || "User"
                        }`
                      : user?.name || "User"}
                  </div>
                  <img
                    src={profileImage || ProfilePlaceholder}
                    alt={user ? user.name : "User"}
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: "50%",
                      marginLeft: "15%",
                      marginRight: "10%",
                      margin: "auto",
                      display: "block",
                      border: "3px solid #E29578",
                      boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.6)",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={
                  familyLogedIn ? handleFamilyMenuClose : handleMenuClose
                }
              >
                <MenuItem
                  onClick={handleLogout}
                  style={{ fontFamily: "Verdana, sans-serif" }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              {isMobile ? (
                <>
                  <Typography
                    variant="h5"
                    style={{
                      marginLeft: 50,
                      fontFamily: "'Days One', sans-serif",
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }}
                  >
                    Memory Diaries
                  </Typography>
                  <Button
                    color="inherit"
                    style={{
                      marginLeft: "auto",
                      fontFamily: "'Days One', sans-serif",
                      fontSize: 16,
                    }}
                    onClick={handleLoginClick}
                  >
                    Your Login
                  </Button>
                  <Button
                    color="inherit"
                    onClick={handleRegisterClick}
                    style={{
                      fontFamily: "'Days One', sans-serif",
                      fontSize: 16,
                    }}
                  >
                    Register
                  </Button>
                </>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    style={{
                      marginLeft: 50,
                      fontFamily: "'Days One', sans-serif",
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }}
                  >
                    Memory Diaries
                  </Typography>
                  <Button
                    color="inherit"
                    style={{
                      marginLeft: "auto",
                      fontFamily: "'Days One', sans-serif",
                      fontSize: 16,
                    }}
                    onClick={handleLoginClick}
                  >
                    Your Login
                  </Button>
                  <Button
                    color="inherit"
                    onClick={handleRegisterClick}
                    style={{
                      fontFamily: "'Days One', sans-serif",
                      fontSize: 16,
                    }}
                  >
                    Register
                  </Button>
                  <Button
                    color="inherit"
                    onClick={handleFamilyClick}
                    style={{
                      fontFamily: "'Days One', sans-serif",
                      fontSize: 16,
                    }}
                  >
                    Family Login
                  </Button>
                </>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      <Container>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/home" element={<Welcome />} />
          <Route
            path="/faq"
            element={<FrequentlyAskedQuestions onClose={handleFAQClose} />}
          />
          <Route
            path="/privacy"
            element={<PrivacyPolicy onClose={handlePrivacyPolicyClose} />}
          />
          <Route
            path="/security"
            element={<SecurityPolicy onClose={handleSecurityPolicyClose} />}
          />
          <Route
            path="/aboutus"
            element={<AboutUs onClose={handleAboutUsClose} />}
          />
          <Route
            path="/termsofuse"
            element={<TermsOfUse onClose={handleTermsClose} />}
          />
          <Route
            path="/affiliate"
            element={<Affiliate onClose={handleAffiliateClose} />}
          />
          <Route path="/blog" element={<Blog onClose={handleBlogClose} />} />
          {loggedIn && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/mydocuments" element={<MyDocuments />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route path="/update-payment" element={<UpdatePayment />} />
              <Route path="/add-family" element={<AddFamily />} />
              <Route path="/edit-family" element={<EditFamily />} />
              <Route path="/familydocuments" element={<FamilyDocuments />} />
              <Route path="/familyprofile" element={<FamilyProfile />} />
              <Route
                path="/familyeditprofile"
                element={<FamilyEditProfile />}
              />
              <Route path="/newpageform" element={<NewPageForm />} />
              {pageConfigs &&
                pageConfigs.length > 0 &&
                Array.isArray(pageConfigs) &&
                pageConfigs
                  .filter((config) => config.pageType === "story")
                  .map((config) => (
                    <Route
                      key={config.storageKey}
                      path={`/stories/${config.storageKey}`}
                      element={
                        <DynamicStoriesPage
                          pageTitle={config.pageTitle}
                          storyLabel={config.storyLabel}
                          storageKey={config.storageKey}
                        />
                      }
                    />
                  ))}
              {pageConfigs &&
                pageConfigs.length > 0 &&
                Array.isArray(pageConfigs) &&
                pageConfigs
                  .filter((config) => config.pageType === "photo")
                  .map((config) => (
                    <Route
                      key={config.storageKey}
                      path={`/photos/${config.storageKey}`}
                      element={
                        <DynamicPhotosPage
                          pageTitle={config.pageTitle}
                          storyLabel={config.storyLabel}
                          storageKey={config.storageKey}
                        />
                      }
                    />
                  ))}
              {pageConfigs &&
                pageConfigs.length > 0 &&
                Array.isArray(pageConfigs) &&
                pageConfigs
                  .filter((config) => config.pageType === "video")
                  .map((config) => (
                    <Route
                      key={config.storageKey}
                      path={`/videos/${config.storageKey}`}
                      element={
                        <DynamicVideosPage
                          pageTitle={config.pageTitle}
                          storyLabel={config.storyLabel}
                          storageKey={config.storageKey}
                        />
                      }
                    />
                  ))}
              <>
                {pageConfigs &&
                  pageConfigs.length > 0 &&
                  Array.isArray(pageConfigs) &&
                  pageConfigs
                    .filter((config) => config.pageType === "story")
                    .map((config) => (
                      <Route
                        key={config.storageKey}
                        path={`/stories/family/${config.storageKey}`}
                        element={
                          <FamilyDynamicStories
                            pageTitle={config.pageTitle}
                            storyLabel={config.storyLabel}
                            storageKey={config.storageKey}
                          />
                        }
                      />
                    ))}
                {pageConfigs &&
                  pageConfigs.length > 0 &&
                  Array.isArray(pageConfigs) &&
                  pageConfigs
                    .filter((config) => config.pageType === "photo")
                    .map((config) => (
                      <Route
                        key={config.storageKey}
                        path={`/photos/family/${config.storageKey}`}
                        element={
                          <FamilyDynamicPhotos
                            pageTitle={config.pageTitle}
                            storyLabel={config.storyLabel}
                            storageKey={config.storageKey}
                          />
                        }
                      />
                    ))}
                {pageConfigs &&
                  pageConfigs.length > 0 &&
                  Array.isArray(pageConfigs) &&
                  pageConfigs
                    .filter((config) => config.pageType === "video")
                    .map((config) => (
                      <Route
                        key={config.storageKey}
                        path={`/videos/family/${config.storageKey}`}
                        element={
                          <FamilyDynamicVideos
                            pageTitle={config.pageTitle}
                            storyLabel={config.storyLabel}
                            storageKey={config.storageKey}
                          />
                        }
                      />
                    ))}
              </>
            </>
          )}
        </Routes>
        {loginOpen && (
          <Login
            open={loginOpen}
            onClose={() => setLoginOpen(false)}
            onLoginSuccess={handleLoginSuccess} // Pass function reference correctly
          />
        )}
        {registerOpen && (
          <Register
            open={registerOpen}
            onClose={() => setRegisterOpen(false)}
            onRegisterSuccess={handleRegisterSuccess}
          />
        )}
        {resetPassOpen && (
          <ResetPass open={resetPassOpen} onClose={handleResetPassClose} />
        )}
        {familyOpen && (
          <FamilyLogin
            open={familyOpen}
            onClose={() => setFamilyOpen(false)}
            onFamilySuccess={handleFamilySuccess}
          />
        )}
        {loggedIn && !isPaid && (
          <Checkout
            open={checkoutOpen}
            onClose={handleCheckoutClose}
            onCheckoutSuccess={handleCheckoutSuccess}
            onCheckoutFailure={handleCheckoutFailure}
            customerEmail={user.email}
          />
        )}
      </Container>
    </div>
  );
}

export default App;
