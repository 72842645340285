import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector, useDispatch } from "react-redux";
import { saveDocument, deleteDocument } from "../store/MyDocumentSlice";
import axiosInstance from "./axiosConfig";
import logger from "../utils/logger";
import FileIcon from "./FileIconMap";

const MyDocuments = () => {
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const loading = useSelector((state) => state.myDocuments.loading);
  const error = useSelector((state) => state.myDocuments.error);

  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  // eslint-disable-next-line
  const [description, setDescriptions] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!refresh) return;

    const fetchDocuments = async () => {
      setFetching(true);
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com.au/lifeData/documents/download",
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );
        logger.debug("Response data:", response.data);
        logger.info("Documents fetched successfully.");
        setDocuments(response.data);
        if (Array.isArray(response.data)) {
          setDescriptions(response.data.map(() => "")); // Initialize descriptions
        } else {
          logger.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        logger.error("Error fetching photos:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchDocuments();
    setRefresh(false);
  }, [refresh]);

  const allowedFileTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.apple.pages",
    "text/plain",
  ];
  const maxFileSize = 10 * 1024 * 1024; // 10 MB

  const allowedExtensions = [".pdf", ".doc", ".docx", ".pages", ".txt"]; // Include ".pages"

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name
        .slice(file.name.lastIndexOf("."))
        .toLowerCase(); // Get the file extension
      const isExtensionValid = allowedExtensions.includes(fileExtension);
      const isTypeValid = allowedFileTypes.includes(file.type);

      if (!isExtensionValid || (fileExtension !== ".pages" && !isTypeValid)) {
        setErrorMessage("File type not allowed.");
        setSelectedFile(null);
      } else if (file.size > maxFileSize) {
        setErrorMessage("File size exceeds 10 MB.");
        setSelectedFile(null);
      } else {
        setErrorMessage("");
        const sanitizedFilename = file.name.replace(/[^a-zA-Z0-9._-]/g, "");
        const sanitizedFile = new File([file], sanitizedFilename, {
          type: file.type,
        });
        setSelectedFile(sanitizedFile);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      setUploading(true);
      logger.debug("In handle submit selected file", selectedFile);
      const file = new FormData();
      file.append("file", selectedFile);
      try {
        await dispatch(saveDocument(file));
        logger.info("Document save dispatched successfully.");
        setRefresh(true);
      } catch (error) {
        logger.error("Error saving document:", error);
      }
      setSelectedFile(null);
      setUploading(false);
    }
  };

  const handleDelete = async (docKey) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        await dispatch(deleteDocument(docKey));
        logger.info("Document delete dispatched successfully.");
        setRefresh(true);
      } catch (error) {
        logger.error("Error deleting document:", error);
      }
    }
  };

  const handleDownload = (docKey) => {
    const doc = documents.find((doc) => doc.Key === docKey);
    if (doc) {
      const { base64, contentType, Key } = doc;

      if (base64 && contentType) {
        const binaryString = atob(base64);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: contentType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", Key);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        console.error("Missing base64 data or contentType for the document.");
      }
    } else {
      console.error("Document not found.");
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        My Documents
      </Typography>
      <Typography
        style={{ fontFamily: "'Days One', sans-serif" }}
        variant="h5"
        component="h2"
        gutterBottom
      >
        Upload Documents Related to Your Story
      </Typography>
      <p style={{ fontFamily: "Verdana, sans-serif" }}>
        Allowed File Types: PDF, DOC, DOCX, PAGES, TXT
      </p>
      <p style={{ fontFamily: "Verdana, sans-serif" }}>
        Maximum file size: 10MB
      </p>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: "none", margin: "16px 0" }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            variant="outlined"
            component="span"
            sx={{
              margin: "16px 0",
              borderColor: "#E29578",
              backgroundColor: "#E29578",
              "&:hover": {
                backgroundColor: "#FFDDD2",
              },
              fontFamily: "'Days One', sans-serif",
              color: "white",
              padding: "5px 10px",
              borderRadius: 10,
            }}
          >
            Choose file
          </Button>
        </label>
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{
            margin: "16px 0",
            borderColor: "#E29578",
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            color: "white",
            marginLeft: 20,
            padding: "5px 10px",
            borderRadius: 10,
            fontFamily: "'Days One', sans-serif",
          }}
          disabled={loading || !selectedFile}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </Box>
      {selectedFile && (
        <Box>
          <Typography style={{ fontFamily: "Verdana" }}>
            {selectedFile.name}
          </Typography>
        </Box>
      )}
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Uploaded Documents
        </Typography>
        {fetching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress color="success" />
            <Typography
              variant="body1"
              style={{ fontFamily: "'Days One', sans-serif" }}
            >
              Loading documents...
            </Typography>
          </Box>
        ) : documents.length > 0 ? (
          <List>
            {documents.map((doc, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow: 3,
                  mb: 2,
                }}
              >
                <IconButton
                  aria-label="download"
                  sx={{
                    marginRight: 1,
                    width: 80,
                    height: 80,
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                    "& svg": {
                      fontSize: 48,
                    },
                  }}
                >
                  <FileIcon fileType={{ name: doc.Key }} />
                </IconButton>
                <ListItemText
                  primary={doc.name || doc.Key}
                  style={{ fontFamily: "Verdana, sans-serif" }}
                />
                <div>
                  <IconButton
                    aria-label="download"
                    onClick={() => handleDownload(doc.Key)}
                    sx={{
                      marginRight: 1,
                      width: 100,
                      height: 100,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "& svg": {
                        fontSize: 48,
                      },
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(doc.Key)}
                    sx={{
                      marginRight: 1,
                      width: 100,
                      height: 100,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "& svg": {
                        fontSize: 48,
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No documents uploaded yet.
          </Typography>
        )}
      </Box>
      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{ mt: 2, fontFamily: "Verdana, sans-serif" }}
        >
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default MyDocuments;
