// store/familySlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { clearAllData } from "./fetchBackendData";
import logger from "../utils/logger";

// Async thunk to add a family member
export const addFamilyMember = createAsyncThunk(
  "family/addFamilyMember",
  async (familyMemberData, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com.au/lifeauth/addFamily`,
        familyMemberData,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      if (response.status === 400) {
        logger.error("Error adding family member:", response.data);
        return rejectWithValue(response.data);
      }

      return response.data;
    } catch (error) {
      logger.error("Error adding family member:", error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to fetch family members
export const fetchFamilyMembers = createAsyncThunk(
  "family/fetchFamilyMembers",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.get(
        `https://memorydiaries.com.au/lifeauth/getFamily`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error fetching family members:", error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update a family member
export const updateFamilyMember = createAsyncThunk(
  "family/updateFamilyMember",
  async (familyMemberData, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com.au/lifeauth/updateFamily`,
        familyMemberData,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error updating family member:", error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to delete a family member
export const deleteFamilyMember = createAsyncThunk(
  "family/deleteFamilyMember",
  async (email, { rejectWithValue, getState }) => {
    logger.debug("Email for deleteing family member:", email);
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeauth/deleteFamilyMember`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          params: { email },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error deleting family member:", error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateFamilyDetails = createAsyncThunk(
  "family/updateFamilyDetails",
  async (familyData, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com.au/lifeauth/update-family-details`,
        familyData,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error updating family details:", error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  familyMembers: [],
  loading: false,
  error: null,
};

const familySlice = createSlice({
  name: "family",
  initialState,
  reducers: {
    logoutFamily: (state) => {
      state.familyMembers = [];
    },
    clearError: (state) => {
      state.error = null;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFamilyMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addFamilyMember.fulfilled, (state, action) => {
        state.loading = false;
        if (!Array.isArray(state.familyMembers)) {
          state.familyMembers = [];
        }
        state.familyMembers.push(action.payload);
      })
      .addCase(addFamilyMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchFamilyMembers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFamilyMembers.fulfilled, (state, action) => {
        state.loading = false;
        state.familyMembers = action.payload;
      })
      .addCase(fetchFamilyMembers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateFamilyMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFamilyMember.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.familyMembers.findIndex(
          (member) => member.familyId === action.payload.familyId
        );
        if (index !== -1) {
          state.familyMembers[index] = action.payload;
        }
      })
      .addCase(updateFamilyMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteFamilyMember.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFamilyMember.fulfilled, (state, action) => {
        state.loading = false;
        state.familyMembers = state.familyMembers.filter(
          (member) => member.email !== action.meta.arg
        );
      })
      .addCase(deleteFamilyMember.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateFamilyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFamilyDetails.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.familyMembers.findIndex(
          (member) => member.familyId === action.payload.familyId
        );
        if (index !== -1) {
          state.familyMembers[index] = action.payload;
        }
      })
      .addCase(updateFamilyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(clearAllData.fulfilled, (state) => {
        state.familyMembers = [];
      });
  },
});

export const { logoutFamily, clearError, resetState } = familySlice.actions;

export default familySlice.reducer;
