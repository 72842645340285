import React, { useState, useRef, useEffect } from "react";
import {
  CircularProgress,
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import {
  saveVideo,
  deleteVideo,
  editDescription,
  deleteDescription,
} from "../store/DynamicVideoSlice";
import axiosInstance from "./axiosConfig";
import LinearProgress from "@mui/material/LinearProgress";
import ShowVideoModal from "./ShowVideoModal";
import ShowVideoRecord from "./ShowVideoRecord";
import logger from "../utils/logger";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PropTypes from "prop-types";

const DynamicVideosPage = ({ pageTitle, storyLabel, storageKey }) => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const error = useSelector((state) => state.dynamicVideos?.error);
  const videoDescriptions = useSelector(
    (state) => state.dynamicVideos?.[storageKey]?.dynamicVideos || []
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [descriptions, setDescriptions] = useState(videoDescriptions);
  const [errorMessage, setErrorMessage] = useState("");
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const [refresh, setRefresh] = useState(true);
  const [fetching, setFetching] = useState(true);
  const name = useSelector((state) => state.auth.user.name);
  const { csrfToken } = useSelector((state) => state.auth);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [recordOpen, setRecordOpen] = useState(false);
  const webcamRef = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(null); // Track which story is playing
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const selectedVoice = useSelector(
    (state) => state.selectedVoice?.selectedVoice || null
  );
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingFilename, setDownloadingFilename] = useState("");

  useEffect(() => {
    if (videos.length > 0 && videoDescriptions.length > 0) {
      logger.debug("Video descriptions:", videoDescriptions);
      const updatedDescriptions = videos.map((video) => {
        const matchingDescription = videoDescriptions.find(
          (desc) => String(desc.filename) === String(video.filename)
        );
        return matchingDescription ? matchingDescription.text : "";
      });
      logger.debug("Updated description:", updatedDescriptions);
      setDescriptions(updatedDescriptions);
      logger.debug("Videodescriptions:", videoDescriptions);
    }
    logger.debug("Videos:", videos);
    logger.debug("Video Descriptions:", videoDescriptions);
  }, [videos, videoDescriptions]);

  useEffect(() => {
    if (!refresh) return;
    getThumbnails();
    setRefresh(false);
  }, [refresh, csrfToken]);

  useEffect(() => {
    setVideos([]);
  }, [storageKey]);

  useEffect(() => {
    getThumbnails();
  }, [storageKey]);

  const getThumbnails = async () => {
    setFetching(true);
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com.au/lifeData/videos/thumbnails",
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.debug("Response data:", response.data);
      logger.info("Thumbnails fetched successfully");
      if (Array.isArray(response.data)) {
        response.data.forEach((item) => {
          logger.debug(`item.filename: ${item.filename}`);
        });

        const filteredData = response.data.filter(
          (item) => item.filename && item.filename.startsWith(`${storageKey}_`)
        );

        const updatedVideos = filteredData.map((video) => {
          logger.debug(`video name: ${video.filename}`);
          const modifiedStorageKey = video.filename
            ? video.filename.replace(new RegExp(`${storageKey}_`), "") // Remove everything before and including the first underscore
            : ""; // Default to an empty string or handle differently if filename is undefined

          return { ...video, filename: modifiedStorageKey }; // Update the storageKey field
        });

        setVideos(updatedVideos);
        setDescriptions(updatedVideos.map(() => ""));
      } else {
        logger.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      logger.error("Error fetching thumbnails:", error);
    }
    setFetching(false);
  };

  useEffect(() => {
    logger.debug("useEffect triggered with selectedFile:", selectedFile);
    const saveAndRefresh = async () => {
      if (selectedFile && selectedFile.type === "video/mp4") {
        logger.debug("Dispatching saveVideo with file:", selectedFile);
        try {
          setFetching(true);
          const modifiedFileName = `${storageKey}_${selectedFile.name}`;

          // Create a new File object with the modified name
          const modifiedFile = new File([selectedFile], modifiedFileName, {
            type: selectedFile.type,
          });
          await dispatch(saveVideo(modifiedFile));
          logger.info("Video saved successfully");
          setRefresh(true);
        } catch (error) {
          logger.log("Error saving video");
        }
        setSelectedFile(null);
        setIsConverting(false);
        setErrorMessage("");
      }
    };
    saveAndRefresh();
  }, [selectedFile, dispatch]);

  const allowedFormats = [
    "video/mp4",
    "video/avi",
    "video/quicktime",
    "video/x-matroska",
    "video/x-flv",
    "video/x-ms-wmv",
    "video/mpeg",
    "video/webm",
  ];
  const maxFileSize = 1 * 1024 * 1024 * 1024; // 1 GB

  const validateVideoFile = (file) => {
    const allowedFormats = [
      "video/mp4",
      "video/avi",
      "video/quicktime",
      "video/x-matroska",
      "video/x-flv",
      "video/x-ms-wmv",
      "video/mpeg",
      "video/webm",
    ];

    const maxSizeInBytes = 1 * 1024 * 1024 * 1024; // 1 GB

    if (!allowedFormats.includes(file.type)) {
      throw new Error("Unsupported file format.");
    }
    if (file.size > maxSizeInBytes) {
      throw new Error(
        "Uploaded file exceeds the maximum size limit of 400 MB."
      );
    }

    return true;
  };

  const handleOpenVideoModal = async (streamingUrl) => {
    setShowVideo(true);
    try {
      const response = await axiosInstance.get(streamingUrl, {
        headers: {
          "csrf-token": csrfToken,
        },
        credentials: "include",
        responseType: "blob",
      });

      if (response.status === 200) {
        const videoObjectUrl = URL.createObjectURL(response.data);
        setSelectedVideo(videoObjectUrl);
      } else {
        console.error("Failed to fetch video:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching video:", error);
    }
  };

  const handleCloseVideoModal = () => {
    setSelectedVideo(null);
    setShowVideo(false);
  };

  const checkVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file);
      const videoElement = document.createElement("video");
      videoElement.src = url;
      videoElement.addEventListener("loadedmetadata", () => {
        if (videoElement.duration > 0) {
          resolve(true);
        } else {
          reject(new Error("Video duration is invalid."));
        }
        URL.revokeObjectURL(url);
      });

      videoElement.addEventListener("error", () => {
        reject(new Error("Error loading video metadata."));
        URL.revokeObjectURL(url);
      });
    });
  };

  const compressVideoFile = async (file) => {
    setUploadProgress(0);

    try {
      validateVideoFile(file);
      await checkVideoDuration(file);
      logger.info("Video validated in compress function");

      const formData = new FormData();
      formData.append("file", file);
      formData.append("format", "video/mp4");
      const startTime = Date.now();

      const response = await axiosInstance.post(
        "https://memorydiaries.com.au/lifeConvert/videos/convert",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          responseType: "blob",
          withCredentials: true,
          timeout: 31200000,
          onUploadProgress: (progressEvent) => {
            const totalBytes = progressEvent.total || 0;
            const loadedBytes = progressEvent.loaded || 0;

            const totalMB = totalBytes / (1024 * 1024);
            const loadedMB = loadedBytes / (1024 * 1024);
            const uploadPercentCompleted = Math.round(
              (loadedMB * 100) / totalMB
            );

            const adjustedPercentCompleted = Math.min(
              100,
              uploadPercentCompleted
            );
            setUploadProgress(adjustedPercentCompleted);
          },
        }
      );

      const endTime = Date.now();
      const durationInSeconds = (endTime - startTime) / 1000;

      logger.info("Response data received.");
      const originalFileName = file.name.split(".").slice(0, -1).join(".");
      const newFileName = `${originalFileName}.mp4`;
      const videoBlob = new Blob([response.data], { type: "video/mp4" });
      const videoBlobWithName = new File([videoBlob], newFileName, {
        type: "video/mp4",
      });
      logger.debug("Video blob with name:", videoBlobWithName);
      const url = URL.createObjectURL(videoBlobWithName);
      setSelectedFile(videoBlobWithName);
      URL.revokeObjectURL(url);
      setUploadProgress(0);
      logger.debug("Compressed video file:", videoBlobWithName);

      const fileSizeInMB = file.size / (1024 * 1024);
      const conversionSpeedInMBPerMin = fileSizeInMB / (durationInSeconds / 60); // Speed in MB/min
      logger.debug(
        `Total conversion speed: ${conversionSpeedInMBPerMin.toFixed(2)} MB/min`
      );
      logger.info("Video compressed successfully");
    } catch (error) {
      logger.error("Error compressing video:", error);
      setErrorMessage(
        "Failed to convert video: " + error.response?.data?.error ||
          error.message
      );
      setIsConverting(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsConverting(true);

    if (!selectedFile) {
      setErrorMessage("Please select a file first.");
      setIsConverting(false);
      return;
    }

    if (selectedFile.size > maxFileSize) {
      setErrorMessage("File size exceeds 1GB.");
      setSelectedFile(null);
      setIsConverting(false);
      return;
    }

    if (!allowedFormats.includes(selectedFile.type)) {
      setErrorMessage(
        "Invalid file format. Please upload a video in a supported format."
      );
      setSelectedFile(null);
      setIsConverting(false);
      return;
    }

    if (selectedFile.type === "video/x-m4v") {
      setErrorMessage("M4V format is not supported.");
      setSelectedFile(null);
      setIsConverting(false);
      return;
    }

    if (selectedFile.type === "video/mp4") {
      setSelectedFile(null);
      setIsConverting(false);
      setErrorMessage("");
      logger.info("Video is already mp4 format");
      return;
    } else {
      await compressVideoFile(selectedFile);
    }
  };

  const handleSubmitRecordedFile = async (file) => {
    setIsConverting(true);

    if (file.size > maxFileSize) {
      setErrorMessage("File size exceeds 1GB.");
      setSelectedFile(null);
      setIsConverting(false);
      return;
    }
    await compressVideoFile(file);
  };

  const handleRecordStart = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert(
        "Media devices not supported in this browser. Please use a different browser."
      );
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm",
        });

        const sanitizedName = name.replace(/\s+/g, "_");

        const videoNumbers = videos
          .filter(
            (video) =>
              video && video.Key && video.Key.startsWith(`${sanitizedName}_`)
          )
          .map((video) => {
            const match = video.Key.match(/_(\d+)/);
            return match ? parseInt(match[1], 10) : 0;
          });

        const maxNumber =
          videoNumbers.length > 0 ? Math.max(...videoNumbers) : 0;
        const nextNumber = maxNumber > 0 ? maxNumber + 1 : 1;
        const file = new File([blob], `${sanitizedName}_${nextNumber}.webm`, {
          type: "video/webm",
        });

        recordedChunksRef.current = [];
        stream.getTracks().forEach((track) => track.stop());
        try {
          handleSubmitRecordedFile(file);
          logger.info("Video saved successfully");
          setRefresh(true);
        } catch (error) {
          logger.error("Error saving video:", error);
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      alert("Permission to record denied");
    }
  };

  const handleDescriptionChange = (index, event) => {
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(event.target.value);
      const newDescriptions = [...descriptions];
      newDescriptions[index] = sanitizedDescription;
      setDescriptions(newDescriptions);
    } else {
      logger.error("Description index out of bounds");
    }
  };

  const handleSave = (filename, index, storageKey) => {
    logger.debug(
      `Saving description with key, index and storageKey: ${filename}, ${index}, ${storageKey}`
    );
    logger.debug(`Decription: ${descriptions[index]}`);
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(descriptions[index]);
      logger.debug(`Sanitized description: ${sanitizedDescription}`);
      setDescriptions((prevDescriptions) => {
        const newDescriptions = [...prevDescriptions];
        newDescriptions[index] = sanitizedDescription;
        return newDescriptions;
      });
      logger.debug(
        `Storage key: ${storageKey}, Video key: ${filename}, Text: ${sanitizedDescription}`
      );

      dispatch(
        editDescription({
          storageKey: storageKey,
          filename: filename,
          text: sanitizedDescription,
        })
      );
      logger.info("Description saved successfully");
    }
    setIsEditing(null);
    setRefresh(true);
  };

  const handleEdit = (index) => {
    setIsEditing(index);
  };

  const handleRecordStop = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordOpen(false);
    } else {
      setRecordOpen(false);
    }
  };

  const handleDelete = async (filename, storageKey) => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      logger.debug(
        `For Deleteion route Storage key: ${storageKey}, File key: ${filename}`
      );
      const fileKey = `${storageKey}_${filename}`; // Combine storageKey with fileKey
      logger.debug(
        `For Deleteion route Storage key with fileKeyWithStorage: ${fileKey}`
      );
      await dispatch(deleteVideo({ fileKey }));
      logger.info("Video deleted successfully");
      dispatch(deleteDescription({ storageKey: storageKey, filename }));
      logger.info("Video description deleted successfully");
      setRefresh(true);
    }
  };

  const handleDownload = async (videoKey) => {
    setIsDownloading(true);
    setDownloadingFilename(videoKey);
    logger.debug(`Video key ${videoKey} or JSON ${JSON.stringify(videoKey)}`);
    try {
      const videoName = `${storageKey}_${videoKey}`;
      const response = await axiosInstance.get(
        `https://memorydiaries.com.au/lifeData/videos/download/${videoName}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          responseType: "blob",
        }
      );

      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      const modifiedName = videoName.replace(/^.*?_/, "");

      link.href = url;
      link.setAttribute("download", modifiedName); // Use the correct filename with the storage key and video key
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading video:", error);
    }
    setIsDownloading(false);
    setDownloadingFilename("");
  };

  const toggleIsRecording = () => {
    setRecordOpen(!recordOpen);
  };

  const handleFileChange = async (event) => {
    const videoFile = event.target.files[0];
    setSelectedFile(videoFile);
  };

  const handlePlayPauseTTS = (text, index) => {
    if (isPlaying === index) {
      speechSynthesis.cancel();
      setIsPlaying(null);
    } else {
      if (speechSynthesisInstance) speechSynthesis.cancel(); // Stop any existing instance
      const utterance = new SpeechSynthesisUtterance(text);

      const availableVoices = speechSynthesis.getVoices();
      const fallbackVoice =
        availableVoices.find((voice) => voice.lang === "en-US") ||
        availableVoices[0]; // Default to first available voice

      utterance.voice =
        availableVoices.find((voice) => voice.name === selectedVoice) ||
        fallbackVoice;

      speechSynthesis.speak(utterance);
      setIsPlaying(index);

      utterance.onend = () => setIsPlaying(null);
      setSpeechSynthesisInstance(utterance);
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {pageTitle}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        style={{ fontFamily: "'Days One', sans-serif" }}
      >
        Upload Videos Related to Your Story
      </Typography>
      <p style={{ fontFamily: "Verdana, sans-serif" }}>
        Allowed formats: MP4, AVI, MOV, MKV, FLV, WMV, MPEG, WebM
      </p>
      <p style={{ fontFamily: "Verdana, sans-serif" }}>
        Maximum file size: 1GB. Larger files take longer to compress
      </p>
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <input
            type="file"
            accept="video/*"
            onChange={handleFileChange}
            style={{ display: "none", margin: "16px 0" }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="outlined"
              component="span"
              sx={{
                padding: "5px 10px",
                margin: "16px 0",
                backgroundColor: "#E29578",
                "&:hover": {
                  backgroundColor: "#FFDDD2",
                },
                color: "white",
                cursor: "pointer",
                borderRadius: 10,
                fontFamily: "'Days One', sans-serif",
              }}
            >
              Choose Video
            </Button>
          </label>
          <Button
            type="submit"
            variant="contained"
            sx={{
              margin: "16px 0",
              padding: "5px 10px",
              backgroundColor: "#E29578",
              "&:hover": {
                backgroundColor: "#FFDDD2",
              },
              borderRadius: 10,
              fontFamily: "'Days One', sans-serif",
              marginLeft: 20,
              color: "white",
            }}
            disabled={!selectedFile || isConverting}
          >
            {isConverting ? "Converting and Saving..." : "Upload"}
          </Button>
        </div>
        {selectedFile && (
          <Box>
            <Typography style={{ fontFamily: "Verdana" }}>
              {selectedFile.name}
            </Typography>
            <Typography style={{ marginLeft: 20, fontFamily: "Verdana" }}>
              Estimated upload time:{" "}
              {selectedFile
                ? (() => {
                    const totalSeconds =
                      ((selectedFile.size * 8) / 10000000 / 18) * 60; // Convert minutes to seconds

                    if (totalSeconds < 60) {
                      return `${totalSeconds.toFixed(0)} sec`;
                    } else if (totalSeconds < 3600) {
                      const minutes = Math.floor(totalSeconds / 60);
                      const seconds = Math.floor(totalSeconds % 60);
                      return `${minutes} min ${seconds} sec`;
                    } else {
                      const hours = Math.floor(totalSeconds / 3600);
                      const minutes = Math.floor((totalSeconds % 3600) / 60);
                      const seconds = Math.floor(totalSeconds % 60);
                      return `${hours} hrs ${minutes} min ${seconds} sec`;
                    }
                  })()
                : 0}
            </Typography>
          </Box>
        )}
        {uploadProgress > 0 && (
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            sx={{
              mt: 2,
              mb: 2,
            }}
            color="success"
          />
        )}
      </Box>

      <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Record a Video
        </Typography>
        <IconButton
          color="default"
          onClick={toggleIsRecording}
          aria-label="record ideo"
          sx={{
            marginLeft: 2,
            color: recordOpen ? "red" : "inherit",
            marginRight: 1,
            width: 100,
            height: 100,
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
            "& svg": {
              fontSize: 48,
            },
          }}
        >
          <VideocamIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          {storyLabel}
        </Typography>
        {fetching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress color="success" />
            <Typography
              variant="body1"
              style={{ fontFamily: "'Days One', sans-serif" }}
            >
              Loading videos...
            </Typography>
          </Box>
        ) : videos.length > 0 ? (
          <>
            <List>
              {videos.map((video, index) => {
                const streamingUrl = `https://memorydiaries.com/lifeData/videos/download/${storageKey}_${video.filename}`;

                return (
                  <ListItem
                    key={index}
                    sx={{
                      display: "grid",
                      alignItems: "center",
                      gridTemplateColumns: "25% 6% 25% 6%",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "20px",
                      marginBottom: "10px",
                      boxShadow: 3,
                    }}
                  >
                    <Box sx={{ gridColumn: "1 / span 1" }}>
                      <div
                        onClick={() => handleOpenVideoModal(streamingUrl)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={video.thumbnail}
                          alt={`Thumbnail for ${video.filename}`}
                          style={{
                            display: "block",
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Box>

                    {descriptions[index] && (
                      <Box
                        sx={{
                          gridColumn: "2 / span 1",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          aria-label="play-pause"
                          onClick={() =>
                            handlePlayPauseTTS(descriptions[index], index)
                          }
                          sx={{
                            display: "flex",
                            alignItems: "center", // Centers the item vertically
                            justifyContent: "center", // Centers the item horizontally (optional)
                            width: 100,
                            height: 100,
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.1)",
                            },
                            "& svg": {
                              fontSize: 48,
                            },
                          }}
                        >
                          {isPlaying === index ? (
                            <PauseCircleIcon />
                          ) : (
                            <PlayCircleIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}

                    <Box
                      sx={{
                        gridColumn: "3 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "20px",
                        width: "100%",
                        marginRight: "50px",
                      }}
                    >
                      <ListItemText
                        primary={video.filename || `Video ${index + 1}`}
                        sx={{
                          marginBottom: "20px",
                          "& .MuiTypography-root": {
                            // Targeting the typography inside ListItemText
                            fontFamily: "'Days One', sans-serif",
                          },
                        }}
                      />
                      {isEditing === index ? (
                        <>
                          <TextField
                            value={descriptions[index]}
                            onChange={(event) =>
                              handleDescriptionChange(index, event)
                            }
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={6}
                            placeholder="Add a description"
                            margin="normal"
                            style={{
                              fontFamily: "Verdana, sans-serif",
                              width: "100%",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body1"
                            style={{
                              fontFamily: "Verdana, sans-serif",
                              width: "100%",
                            }}
                          >
                            {descriptions[index]}
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box
                      sx={{
                        gridColumn: "4 / span 1",
                        display: "flex",
                        marginLeft: "50px",
                        gap: 1,
                      }}
                    >
                      <IconButton
                        aria-label={isEditing === index ? "save" : "edit"}
                        onClick={() => {
                          isEditing === index
                            ? handleSave(video.filename, index, storageKey)
                            : handleEdit(index);
                        }}
                        sx={{
                          marginRight: 1,
                          width: 100,
                          height: 100,
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                          "& svg": {
                            fontSize: 48,
                          },
                        }}
                      >
                        {isEditing === index ? <SaveIcon /> : <EditIcon />}
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="download"
                        onClick={() => handleDownload(video.filename)}
                        sx={{
                          marginLeft: "16px",
                          marginRight: 1,
                          width: 100,
                          height: 100,
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                          "& svg": {
                            fontSize: 48,
                          },
                        }}
                      >
                        {isDownloading &&
                        downloadingFilename === video.filename ? (
                          <CircularProgress color="success" />
                        ) : (
                          <DownloadIcon />
                        )}
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDelete(video.filename, storageKey)}
                        sx={{
                          marginLeft: "16px",
                          marginRight: 1,
                          width: 100,
                          height: 100,
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                          "& svg": {
                            fontSize: 48,
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No videos uploaded yet.
          </Typography>
        )}
      </Box>

      <ShowVideoRecord
        open={recordOpen}
        handleClose={toggleIsRecording}
        isRecording={isRecording}
        handleRecordStart={handleRecordStart}
        handleRecordStop={handleRecordStop}
        webcamRef={webcamRef}
      />

      <ShowVideoModal
        showVideo={showVideo}
        selectedVideo={selectedVideo}
        handleCloseVideoModal={handleCloseVideoModal}
      />

      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{ mt: 2, fontFamily: "Verdana, sans-serif" }}
        >
          {error}
        </Typography>
      )}
    </Container>
  );
};

DynamicVideosPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  onFamilySuccess: PropTypes.bool.isRequired,
};

export default DynamicVideosPage;
