// src/utils/logger.js
import log from "loglevel";
import { createLogger } from "redux-logger";

// Determine the environment
const isDevelopment = process.env.NODE_ENV === "development";

// Configure `loglevel` for general logging in React components
log.setLevel(isDevelopment ? "debug" : "info"); // Set log level based on environment

// Log level usage
export const logger = {
  debug: (...args) => log.debug(...args),
  info: (...args) => log.info(...args),
  warn: (...args) => log.warn(...args),
  error: (...args) => log.error(...args),
};

// Configure `redux-logger` for Redux action/state logging
export const reduxLogger = isDevelopment
  ? createLogger({
      level: "debug", // Log action and state details
      collapsed: true, // Collapses logs for cleaner readability
      duration: true, // Shows the duration of each action
    })
  : undefined;

export default logger;
