import React, { useState, useEffect, useRef } from "react";
import {
  CircularProgress,
  Button,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import imageCompression from "browser-image-compression";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import {
  savePhoto,
  deletePhoto,
  editDescription,
  deleteDescription,
} from "../store/DynamicPhotoSlice";
import axiosInstance from "./axiosConfig";
import ShowPhotoModal from "./ShowPhotoModal";
import ShowPhotoRecord from "./ShowPhotoRecord";
import logger from "../utils/logger";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";

const supportedFormats = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/tiff",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
  "image/jpg",
];

const DynamicPhotosPage = ({ pageTitle, storyLabel, storageKey }) => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const error = useSelector((state) => state.dynamicPhotos?.error || null);
  const photoDescriptions = useSelector(
    (state) => state.dynamicPhotos?.[storageKey]?.dynamicPhotos || []
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [descriptions, setDescriptions] = useState(photoDescriptions);
  const [isEditing, setIsEditing] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);
  // eslint-disable-next-line
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const webcamRef = useRef(null);
  const name = useSelector((state) => state.auth.user.name);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const handleOpenPhotoModal = (photo) => setSelectedPhoto(photo);
  const handleClosePhotoModal = () => setSelectedPhoto(null);
  const [isPlaying, setIsPlaying] = useState(null); // Track which story is playing
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const selectedVoice = useSelector(
    (state) => state.selectedVoice?.selectedVoice || null
  );

  useEffect(() => {
    if (
      Array.isArray(photos) &&
      photos.length > 0 &&
      Array.isArray(photoDescriptions) &&
      photoDescriptions.length > 0
    ) {
      const updatedDescriptions = photos.map((photo) => {
        const matchingDescription = photoDescriptions.find(
          (desc) => String(desc.Key) === String(photo.Key)
        );
        return matchingDescription ? matchingDescription.text : "";
      });
      setDescriptions(updatedDescriptions);
    }
  }, [photos, editDescription, deleteDescription]);

  useEffect(() => {}, [selectedFile]);

  useEffect(() => {
    if (!refresh) return;

    fetchPhotos();
    setRefresh(false);
  }, [refresh, csrfToken]);

  useEffect(() => {
    setPhotos([]);
  }, [storageKey]);

  useEffect(() => {
    fetchPhotos();
  }, [storageKey]);

  const fetchPhotos = async () => {
    setFetching(true);
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com.au/lifeData/photos/download",
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.debug("Response data:", response.data);
      logger.info("Photos fetched successfully");
      if (Array.isArray(response.data)) {
        const filteredData = response.data.filter(
          (item) => item.Key && item.Key.startsWith(`${storageKey}_`)
        );

        const updatedPhotos = filteredData.map((photo) => {
          logger.debug(`photo name: ${photo.Key}`);
          const modifiedStorageKey = photo.Key
            ? photo.Key.replace(new RegExp(`^${storageKey}_`), "") // Remove everything before and including the first underscore
            : ""; // Default to an empty string or handle differently if filename is undefined

          return { ...photo, Key: modifiedStorageKey }; // Replace the fileKey with the modified one
        });

        setPhotos(updatedPhotos);
        const initialDescriptions = updatedPhotos.map(() => "");
        setDescriptions(initialDescriptions);
        setSelectedFile(null);
      } else {
        logger.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      logger.error("Error fetching photos:", error);
      setErrorMessage("Error fetching photos. Please try again later.");
    } finally {
      setFetching(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setErrorMessage("No file selected");
      return;
    }
    if (!supportedFormats.includes(file.type)) {
      setErrorMessage(
        "Supported formats are: JPG, JPEG, PNG, GIF, TIFF, BMP, WebP, SVG"
      );
      return;
    }
    setErrorMessage("");
    const options = {
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      fileType: "image/jpg",
    };
    try {
      const compressedFile = await imageCompression(file, options);
      const sanitizedFilename = file.name.replace(/[^a-zA-Z0-9._-]/g, "");

      const sanitizedFile = new File([compressedFile], sanitizedFilename, {
        type: compressedFile.type,
        lastModified: Date.now(),
      });
      logger.debug("Compressed file:", sanitizedFile);
      setSelectedFile(sanitizedFile);
    } catch (error) {
      logger.error("Error compressing the image:", error);
      setErrorMessage("Error processing the image");
    }
  };

  const handleTakePhoto = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    if (imageSrc) {
      try {
        const base64Response = await fetch(imageSrc);
        const blob = await base64Response.blob();

        const sanitizedName = name.replace(/\s+/g, "_");

        const photoNumbers = photos
          .filter(
            (photo) => photo.Key && photo.Key.startsWith(`${sanitizedName}_`)
          )
          .map((photo) => {
            const match = photo.Key.match(/_(\d+)/);
            return match ? parseInt(match[1], 10) : 0;
          });

        const maxNumber =
          photoNumbers.length > 0 ? Math.max(...photoNumbers) : 0;
        const nextNumber = maxNumber + 1;

        const fileName = `${sanitizedName}_${nextNumber}.jpg`;
        const photoFile = new File([blob], fileName, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });

        const formData = new FormData();
        const modifiedFileName = `${storageKey}_${photoFile.name}`;
        const modifiedFile = new File([photoFile], modifiedFileName, {
          type: photoFile.type,
        });

        formData.append("file", modifiedFile);

        setCapturedPhoto(photoFile);
        setSelectedFile(photoFile);
        setCameraOpen(false);

        logger.debug(`FileKeyWithStorage: ${formData.name}`);
        await dispatch(savePhoto(formData));
        logger.info("Photo saved successfully");
        setRefresh(true);
      } catch (error) {
        logger.error("Upload failed:", error);
      }
    } else {
      console.warn("No image source available.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      setUploading(true);
      logger.debug("In handle submit selected file", selectedFile);

      const formData = new FormData();
      const modifiedFileName = `${storageKey}_${selectedFile.name}`;
      const modifiedFile = new File([selectedFile], modifiedFileName, {
        type: selectedFile.type,
      });

      formData.append("file", modifiedFile);
      try {
        logger.debug(`FileKeyWithStorage: ${formData.name}`);
        await dispatch(savePhoto(formData));
        logger.info("Photo saved successfully");
        setSelectedFile(null);
        setRefresh(true);
      } catch (error) {
        logger.error("Upload failed:", error);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleDescriptionChange = (index, event) => {
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(event.target.value);
      const newDescriptions = [...descriptions];
      newDescriptions[index] = sanitizedDescription;
      setDescriptions(newDescriptions);
    } else {
      logger.error("Description index out of bounds");
    }
  };

  const handleSave = (index, photoKey, storageKey) => {
    logger.debug(
      `Savigng desc with index, photoKey and storageKey: ${index}, ${photoKey}, ${storageKey}`
    );
    if (descriptions[index] !== undefined) {
      const sanitizedDescription = DOMPurify.sanitize(descriptions[index]);
      logger.debug(`Sanitized description: ${sanitizedDescription}`);
      setDescriptions((prevDescriptions) => {
        const newDescriptions = [...prevDescriptions];
        newDescriptions[index] = sanitizedDescription;
        return newDescriptions;
      });
      logger.debug(
        `Storage key: ${storageKey}, Photo key: ${photoKey}, Text: ${sanitizedDescription}`
      );
      dispatch(
        editDescription({
          storageKey: storageKey,
          key: photoKey,
          text: sanitizedDescription,
        })
      );
      logger.info("Description saved successfully");
      setIsEditing(null);
      setRefresh(true);
    }
  };

  const handleEdit = (index) => {
    setIsEditing(index);
  };

  const handleDelete = async (photoKey, storageKey) => {
    if (window.confirm("Are you sure you want to delete this photo?")) {
      logger.debug(
        `For Deleteion route Storage key: ${storageKey}, File key: ${photoKey}`
      );
      const fileKey = `${storageKey}_${photoKey}`; // Combine storageKey with fileKey
      logger.debug(
        `For Deleteion route Storage key with fileKeyWithStorage: ${fileKey}`
      );
      await dispatch(deletePhoto({ fileKey }));
      logger.info("Photo deleted successfully");
      dispatch(deleteDescription({ storageKey: storageKey, fileKey }));
      logger.info("Photo description deleted successfully");
      setRefresh(true);
    }
  };

  const handleDownload = (photoName) => {
    const selectedPhoto = photos.find((pho) => pho.Key === photoName);

    if (selectedPhoto && selectedPhoto.base64) {
      // Decode base64 if `selectedPhoto.file` contains base64 data
      const binaryString = atob(selectedPhoto.base64);
      const byteArray = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: selectedPhoto.contentType });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = photoName; // Use photoName as the file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } else {
      setErrorMessage("Photo not available for download");
    }
  };

  const handlePlayPauseTTS = (text, index) => {
    if (isPlaying === index) {
      speechSynthesis.cancel();
      setIsPlaying(null);
    } else {
      if (speechSynthesisInstance) speechSynthesis.cancel(); // Stop any existing instance
      const utterance = new SpeechSynthesisUtterance(text);

      const availableVoices = speechSynthesis.getVoices();
      const fallbackVoice =
        availableVoices.find((voice) => voice.lang === "en-US") ||
        availableVoices[0]; // Default to first available voice

      utterance.voice =
        availableVoices.find((voice) => voice.name === selectedVoice) ||
        fallbackVoice;

      speechSynthesis.speak(utterance);
      setIsPlaying(index);

      utterance.onend = () => setIsPlaying(null);
      setSpeechSynthesisInstance(utterance);
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {pageTitle}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        style={{ fontFamily: "'Days One', sans-serif" }}
      >
        Upload Photos Related to Your Story
      </Typography>
      <p style={{ fontFamily: "Verdana, sans-serif" }}>
        Allowed formats: JPG, JPEG, PNG, GIF, TIFF, BMP, WebP, SVG
      </p>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, display: "flex", flexDirection: "row" }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none", margin: "16px 0" }}
          id="file-upload"
        />
        <label htmlFor="file-upload">
          <Button
            variant="outlined"
            component="span"
            sx={{
              margin: "16px 0",
              borderColor: "#E29578",
              backgroundColor: "#E29578",
              "&:hover": {
                backgroundColor: "#FFDDD2",
              },
              color: "white",
              padding: "5px 10px",
              fontFamily: "'Days One', sans-serif",
              borderRadius: 10,
            }}
          >
            Choose Photo
          </Button>
        </label>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          type="submit"
          variant="contained"
          sx={{
            margin: "16px 0",
            padding: "5px 10px",
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            borderRadius: 10,
            fontFamily: "'Days One', sans-serif",
            marginLeft: 20,
            color: "white",
          }}
          disabled={!selectedFile || uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </Box>
      {selectedFile && (
        <Box>
          <Typography>{selectedFile.name}</Typography>
        </Box>
      )}
      <Box sx={{ mt: 4, display: "flex", alignItems: "center" }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          Take a Photo
        </Typography>
        <IconButton
          onClick={() => setCameraOpen(!cameraOpen)}
          aria-label="open-camera"
          sx={{
            marginLeft: 2,
            color: cameraOpen ? "red" : "inherit",
            width: 100,
            height: 100,
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
            "& svg": {
              fontSize: 48,
            },
          }}
        >
          <CameraAltIcon />
        </IconButton>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          {storyLabel}
        </Typography>
        {fetching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress color="success" />
            <Typography
              variant="body1"
              style={{ fontFamily: "'Days One', sans-serif" }}
            >
              Loading photos...
            </Typography>
          </Box>
        ) : photos.length > 0 ? (
          <List>
            {photos.map((photo, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "grid",
                  alignItems: "center",
                  gridTemplateColumns: "30% 6% 25% 6%",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "20px",
                  marginBottom: "10px",
                  boxShadow: 3,
                }}
              >
                <Box sx={{ gridColumn: "1 / span 1" }}>
                  <ListItemAvatar>
                    <Avatar
                      src={`data:${photo.contentType};base64,${photo.base64}`}
                      alt={`Photo ${index + 1}`}
                      variant="square"
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleOpenPhotoModal(photo)}
                    />
                  </ListItemAvatar>
                </Box>

                {descriptions[index] && (
                  <Box
                    sx={{
                      gridColumn: "2 / span 1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      aria-label="play-pause"
                      onClick={() =>
                        handlePlayPauseTTS(descriptions[index], index)
                      }
                      sx={{
                        display: "flex",
                        alignItems: "center", // Centers the item vertically
                        justifyContent: "center", // Centers the item horizontally (optional)
                        width: 100,
                        height: 100,
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.1)",
                        },
                        "& svg": {
                          fontSize: 48,
                        },
                      }}
                    >
                      {isPlaying === index ? (
                        <PauseCircleIcon />
                      ) : (
                        <PlayCircleIcon />
                      )}
                    </IconButton>
                  </Box>
                )}

                <Box
                  sx={{
                    gridColumn: "3 / span 1",
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "20px",
                    width: "100%",
                    marginRight: "50px",
                  }}
                >
                  <ListItemText
                    primary={photo.Key || `Photo ${index + 1}`}
                    sx={{
                      marginBottom: "20px",
                      "& .MuiTypography-root": {
                        // Targeting the typography inside ListItemText
                        fontFamily: "'Days One', sans-serif",
                      },
                    }}
                  />
                  {isEditing === index ? (
                    <>
                      <TextField
                        value={descriptions[index]}
                        onChange={(event) =>
                          handleDescriptionChange(index, event)
                        }
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        placeholder="Add a description"
                        margin="normal"
                        style={{
                          fontFamily: "'Verdana', sans-serif",
                          width: "100%",
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        style={{
                          fontFamily: "'Verdana', sans-serif",
                          marginBottom: "20px",
                          width: "100%",
                        }}
                      >
                        {descriptions[index]}
                      </Typography>
                    </>
                  )}
                </Box>

                <Box
                  sx={{
                    gridColumn: "4 / span 1",
                    display: "flex",
                    marginLeft: "50px",
                    gap: 1,
                  }}
                >
                  <IconButton
                    aria-label={isEditing === index ? "save" : "edit"}
                    onClick={() => {
                      isEditing === index
                        ? handleSave(index, photo.Key, storageKey)
                        : handleEdit(index);
                    }}
                    sx={{
                      marginRight: 1,
                      width: 100,
                      height: 100,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "& svg": {
                        fontSize: 48,
                      },
                    }}
                  >
                    {isEditing === index ? <SaveIcon /> : <EditIcon />}
                  </IconButton>

                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => handleDownload(photo.Key)}
                    sx={{
                      width: 100,
                      height: 100,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "& svg": {
                        fontSize: 48,
                      },
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(photo.Key, storageKey)}
                    sx={{
                      marginLeft: "2%",
                      marginRight: "2%",
                      width: 100,
                      height: 100,
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                      },
                      "& svg": {
                        fontSize: 48,
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No photos uploaded yet.
          </Typography>
        )}
      </Box>
      <ShowPhotoRecord
        open={cameraOpen}
        handleClose={() => setCameraOpen(false)}
        handleTakePhoto={handleTakePhoto}
        webcamRef={webcamRef}
      />

      <ShowPhotoModal
        selectedPhoto={selectedPhoto}
        handleClosePhotoModal={handleClosePhotoModal}
      />
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
    </Container>
  );
};

export default DynamicPhotosPage;
