import React from "react";
import Tree from "../Img/3generations.webp";
import Footer from "./Footer";
import { Typography, Container } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import grandfather from "../Img/grandfather.jpg";
import kidsandipad from "../Img/kidsandipad.webp";
import Grandmotherfather from "../Img/Grandmotherfather.webp";

function AnimatedTypography({ children, ...props }) {
  const animationProps = useSpring({
    from: { opacity: 0, transform: "translate3d(0,40px,0)" },
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    config: { tension: 200, friction: 15 },
  });

  return (
    <animated.div style={animationProps}>
      <Typography {...props}>{children}</Typography>
    </animated.div>
  );
}

const Welcome = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
        margin: 0,
        padding: 0,
        flexGrow: 1,
      }}
    >
      <Container
        style={{
          marginTop: "50px",
          flexGrow: 1,
          padding: 0,
          margin: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 100,
            marginTop: 50,
          }}
        >
          <div
            style={{
              flexDirection: "column",
              marginRight: "5%",
              width: "100%",
            }}
          >
            <AnimatedTypography
              variant="h2"
              paragraph
              style={{
                marginBottom: 50,
                fontWeight: "bold",
                fontFamily: "'Architects Daughter', cursive",
                fontStyle: "italic",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {" "}
              "Your family storybook and time-capsule"
            </AnimatedTypography>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "50px",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
                padding: 0,
                margin: 0,
              }}
            >
              <div
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginRight: "10%",
                }}
              >
                <AnimatedTypography
                  variant="h5"
                  style={{
                    fontFamily: "'Architects Daughter', cursive",
                    marginBottom: 30,
                    fontStyle: "italic",
                    fontSize: 28,
                  }}
                >
                  "She didn’t know this story would become the memory her
                  grandchildren cherish most."
                </AnimatedTypography>
                <AnimatedTypography
                  variant="h5"
                  style={{
                    fontFamily: "'Architects Daughter', cursive",
                    marginBottom: 30,
                    fontStyle: "italic",
                    fontSize: 28,
                  }}
                >
                  "What is the most important piece of advice your grandparents
                  shared with you?"
                </AnimatedTypography>
                <AnimatedTypography
                  variant="h5"
                  style={{
                    fontFamily: "'Architects Daughter', cursive",
                    marginBottom: 30,
                    fontStyle: "italic",
                    fontSize: 28,
                  }}
                >
                  "Privacy and Advanced Security without compromise"
                </AnimatedTypography>
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={Tree}
                  alt="Three genreations looking at iPad"
                  style={{
                    width: "35vw",
                    resize: "contain",
                    marginTop: "2%",
                    borderRadius: 30,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "50px",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 0,
                margin: 0,
              }}
            >
              <img
                src={Grandmotherfather}
                alt="Grandfather, son and kids"
                style={{
                  width: "35vw",
                  resize: "contain",
                  marginTop: "2%",
                  borderRadius: 30,
                }}
              />
              <AnimatedTypography
                variant="h6"
                paragraph
                style={{
                  marginLeft: "10%",
                  fontFamily: "'Days One', sans-serif",
                  fontStyle: "italic",
                }}
              >
                "I wish I could turn back time to when my grandparents were
                still alive, to listen while they shared tales of their past.
                With each passing day memories can grow faint, like old
                photographs fading in the sun, leaving a profound sense of loss
                for the wisdom and heritage that fades out of reach. Do you wish
                you could gather the fragments of their lives - their dreams,
                struggles and joys - and preserve the vibrant tapestry of their
                lives for future generations?"
              </AnimatedTypography>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "50px",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 0,
                margin: 0,
              }}
            >
              <AnimatedTypography
                variant="h6"
                paragraph
                style={{
                  marginRight: "10%",
                  fontFamily: "'Days One', sans-serif",
                  fontStyle: "italic",
                }}
              >
                "Memory Diaries is a platform where you can document and share
                your life events with family members. Share your life’s greatest
                stories and wisdom with the ones you love, creating a treasure
                trove of memories for generations to come. You can create
                personal entries, upload photos and videos, to record memorable
                moments, ensuring that these cherished memories are preserved
                for future generations."
              </AnimatedTypography>
              <img
                src={grandfather}
                alt="Grandfather, Mother and Daughter"
                style={{
                  width: "35vw",
                  resize: "contain",
                  marginTop: "2%",
                  borderRadius: 30,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 0,
                margin: 0,
              }}
            >
              <img
                src={kidsandipad}
                alt="Mother and kids looking at iPad"
                style={{
                  width: "35vw",
                  resize: "contain",
                  marginRight: "10%",
                  marginTop: "2%",
                  borderRadius: 30,
                }}
              />
              <AnimatedTypography
                variant="h6"
                paragraph
                style={{
                  marginRight: "10%",
                  fontFamily: "'Days One', sans-serif",
                  fontStyle: "italic",
                }}
              >
                "The platform enforces strict security and privacy protocols,
                implementing advanced encryption and authentication measures to
                safeguard user data. We do not harvest your personal data under
                any circumstances. We employ current industry world-leading
                cybersecurity best practice. You can confidently share your
                experiences and milestones, knowing that your information is
                protected and accessible only by you and selected family
                members. We do not analyze, harvest or share your data."
              </AnimatedTypography>
            </div>
          </div>
          <div></div>
        </div>
      </Container>
      <div
        style={{
          width: "100%",
          flexGrow: 1,
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default Welcome;
