import React from "react";
import { Link } from "react-router-dom";
import Facebook from "@mui/icons-material/Facebook";
import Pinterest from "@mui/icons-material/Pinterest";
import X from "@mui/icons-material/X";
import Reddit from "@mui/icons-material/Reddit";
import WhatsApp from "@mui/icons-material/WhatsApp";

function Footer() {
  const socialLinks = {
    Facebook:
      "https://www.facebook.com/sharer/sharer.php?u=https://memorydiaries.com.au",
    Pinterest:
      "https://pinterest.com/pin/create/button/?url=https://memorydiaries.com.au",
    X: "https://twitter.com/intent/tweet?url=https://memorydiaries.com.au",
    Reddit: "https://www.reddit.com/submit?url=https://memorydiaries.com.au",
    WhatsApp: "https://api.whatsapp.com/send?text=https://memorydiaries.com.au",
  };

  return (
    <footer style={footerStyle}>
      <div style={{ paddingRight: 20, paddingLeft: 20 }}>
        <h2 style={{ color: "white", textDecoration: "none" }}>Help Menu</h2>
        <p>
          <Link
            to="/privacy"
            style={{ color: "white", textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
        </p>
        <p>
          <Link
            to="/security"
            style={{ color: "white", textDecoration: "none" }}
          >
            Security Policy
          </Link>
          <p>
            <Link to="/faq" style={{ color: "white", textDecoration: "none" }}>
              FAQ
            </Link>
          </p>

          <p>
            <a
              href="mailto:dr.dbrown@icloud.com"
              style={{ color: "white", textDecoration: "none" }}
            >
              Contact Us
            </a>
          </p>
        </p>
      </div>
      <div
        style={{
          display: "flex",
          paddingRight: 20,
          paddingLeft: 20,
          flexDirection: "column",
        }}
      >
        <h2 style={{ color: "white", textDecoration: "none" }}>Info Menu</h2>
        <Link
          to="/aboutus"
          style={{ marginBottom: 20, color: "white", textDecoration: "none" }}
        >
          About Us
        </Link>
        <Link
          to="/blog"
          style={{ marginBottom: 20, color: "white", textDecoration: "none" }}
        >
          Blog
        </Link>

        <Link
          to="/affiliate"
          style={{ marginBottom: 20, color: "white", textDecoration: "none" }}
        >
          Become and Affiliate
        </Link>
        <Link
          to="/termsofuse"
          style={{ marginBottom: 20, color: "white", textDecoration: "none" }}
        >
          Terms of Use
        </Link>
      </div>

      <div style={dividerStyle}></div>
      <div style={bottomRowStyle}>
        <div style={footerIconsStyle}>
          <a
            href={socialLinks.Facebook}
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <Facebook style={{ color: "white" }} />
          </a>
          <a
            href={socialLinks.Pinterest}
            target="_blank"
            rel="noopener noreferrer"
            title="Pinterest"
          >
            <Pinterest style={{ color: "white" }} />
          </a>
          <a
            href={socialLinks.X}
            target="_blank"
            rel="noopener noreferrer"
            title="X (Twitter)"
          >
            <X style={{ color: "white" }} />
          </a>
          <a
            href={socialLinks.Reddit}
            target="_blank"
            rel="noopener noreferrer"
            title="Reddit"
          >
            <Reddit style={{ color: "white" }} />
          </a>
          <a
            href={socialLinks.WhatsApp}
            target="_blank"
            rel="noopener noreferrer"
            title="WhatsApp"
          >
            <WhatsApp style={{ color: "white" }} />
          </a>
        </div>
        <div>
          <p style={{ margin: 0, textAlign: "right" }}>
            © {new Date().getFullYear()} Memory Diaries. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

const footerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  background: "linear-gradient(90deg, #83C5BE, #6FA7A2, #5C8A85)",
  textAlign: "center",
  width: "100%", // Ensures it spans the full viewport width
  marginLeft: 0,
  padding: 0, // Removes any unwanted padding
  borderTop: "1px solid #e7e7e7",
  position: "relative",
  bottom: "0",
  left: "0",
  borderTopLeftRadius: "30px",
  borderTopRightRadius: "30px",
};

const dividerStyle = {
  width: "90%",
  height: "1px",
  backgroundColor: "white",
  margin: "20px auto",
};

const bottomRowStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "90%",
  margin: "0 auto",
  marginTop: "10px",
  color: "white",
};

const footerIconsStyle = {
  display: "flex",
  alignItems: "center",
  gap: "15px",
  marginTop: "10px",
};

export default Footer;
