import React from "react";
import PropTypes from "prop-types";
import PDF from "../Img/PDF.jpg";
import PAGES from "../Img/Pages.png";
import TXT from "../Img/txt.jpg";
import WORD from "../Img/word2.jpg";

const fileIcons = {
  pdf: PDF,
  doc: WORD,
  docx: WORD,
  pages: PAGES,
  txt: TXT,
};

const FileIcon = ({ fileType }) => {
  const type =
    typeof fileType === "object" && typeof fileType.name === "string"
      ? fileType.name
      : null;

  if (!type) {
    console.error("Invalid fileType:", fileType);
    return null;
  }

  const normalizedType = type.split(".").pop().toLowerCase();
  const icon = fileIcons[normalizedType] || TXT;
  return (
    <img
      src={icon}
      alt={`${normalizedType} icon`}
      style={{ width: "80px", height: "80px" }}
    />
  );
};

FileIcon.propTypes = {
  fileType: PropTypes.string.isRequired,
};

export default FileIcon;
