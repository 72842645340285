// src/store/cloudObjectSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { fetchBackendData, clearAllData } from "./fetchBackendData";
import logger from "../utils/logger";
import { log } from "loglevel";

const initialState = {
  documents: [],
  loading: false,
  error: null,
};

export const saveDocument = createAsyncThunk(
  "documents/saveDocument",
  async (file, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com.au/lifeData/documents/upload",
        file,
        {
          headers: {
            "csrf-token": csrfToken,
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteDocument = createAsyncThunk(
  "documents/deleteDocument",
  async (fileKey, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/documents/delete/${fileKey}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteDocumentsForUser = createAsyncThunk(
  "documents/deleteDocumentsForUser",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/documents/deleteAll`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error(error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const MyDocumentSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = state.documents.filter(
          (doc) => doc.Key !== action.payload.Key
        );
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.documents = action.payload?.data?.documents || [];
      })
      .addCase(clearAllData.fulfilled, (state) => {
        return { ...initialState };
      });
  },
});

export default MyDocumentSlice.reducer;
