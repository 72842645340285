// src/store/cloudObjectSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { fetchBackendData, clearAllData } from "./fetchBackendData";
import logger from "../utils/logger";

const initialState = {
  dynamicPhotos: [],
  loading: false,
  error: null,
};

export const savePhoto = createAsyncThunk(
  "photos/savePhoto",
  async (formData, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      logger.debug("Trying to send file", formData);
      const response = await axiosInstance.post(
        "https://memorydiaries.com.au/lifeData/photos/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.info("File sent successfully");
      return response.data;
    } catch (error) {
      logger.error("Error sending file", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deletePhoto = createAsyncThunk(
  "photos/deletePhoto",
  async ({ fileKey }, { rejectWithValue, getState }) => {
    logger.debug(
      `file key in delete function ${fileKey} or JSON ${JSON.stringify(
        fileKey
      )}`
    );
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/photos/delete/${fileKey}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error deleting file", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteAllPhotos = createAsyncThunk(
  "photos/deleteAllPhotos",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/photos/deleteAllPhotos`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error deleting all files", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deletePhotosByStorageKey = createAsyncThunk(
  "photos/deletePhotosByStorageKey",
  async (storageKey, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/photos/deletePhotosByStorageKey`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          data: { storageKey },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      logger.error("Error deleting files by storage key", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const DynamicPhotoSlice = createSlice({
  name: "dynamicPhotos",
  initialState,
  reducers: {
    editDescription: (state, action) => {
      const { storageKey, key, text } = action.payload; // Include storageKey in the payload
      logger.debug(
        `editDescription called with: ${storageKey}, ${key}, ${text}`
      );
      const description = state[storageKey]?.dynamicPhotos?.find(
        (desc) => desc.Key === key
      );
      if (description) {
        description.text = text; // Update existing description
      } else {
        logger.debug(`Adding new description: ${key}, ${text}`);
        if (!state[storageKey]) {
          state[storageKey] = { dynamicPhotos: [] };
        }
        state[storageKey].dynamicPhotos.push({ Key: key, text }); // Add new description
      }
      logger.debug(
        `Updated redux photoDescriptions: ${state[storageKey].dynamicPhotos}`
      );
    },
    deleteDescription: (state, action) => {
      const { storageKey, key } = action.payload; // Include storageKey in the payload
      if (state[storageKey]) {
        state[storageKey].dynamicPhotos = state[
          storageKey
        ].dynamicPhotos.filter((desc) => desc.Key !== key);
        logger.debug(
          "Updated redux photoDescriptions after delete:",
          state[storageKey].dynamicPhotos
        );
      }
    },
    deletePhotoDescriptionsByStorageKey: (state, action) => {
      const { storageKey } = action.payload;
      logger.debug("StorageKey:", storageKey);
      logger.debug("Available keys in dynamicPhotos:", Object.keys(state));

      if (!state[storageKey]) {
        logger.warn(`Key "${storageKey}" not found in dynamicPhotos`);
        return state; // No changes
      }

      if (!Object.hasOwn(state, storageKey)) {
        logger.warn(`Key "${storageKey}" not found in dynamicPhotos.`);
        return state; // Return the state unchanged
      }
      const updatedState = { ...state };
      delete updatedState[storageKey];

      return updatedState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePhoto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePhoto.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(savePhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePhoto.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePhoto.fulfilled, (state, action) => {
        state.loading = false;
        const { storageKey, fileKey } = action.payload;
        if (state[storageKey]) {
          state[storageKey].photos = state[storageKey].photos.filter(
            (pho) => pho.Key !== fileKey
          );
        }
      })
      .addCase(deletePhoto.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAllPhotos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAllPhotos.fulfilled, (state) => {
        state.loading = false;
        return { ...initialState };
      })
      .addCase(deleteAllPhotos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePhotosByStorageKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deletePhotosByStorageKey.fulfilled, (state, action) => {
        state.loading = false;
        delete state[action.meta.arg];
      })
      .addCase(deletePhotosByStorageKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        const fetchedPhotos = action.payload?.data?.dynamicPhotos || {};
        Object.keys(fetchedPhotos).forEach((key) => {
          state[key] = fetchedPhotos[key];
        });
      })
      .addCase(clearAllData.fulfilled, () => {
        return { ...initialState }; // Reset state to initial state
      });
  },
});

export const {
  editDescription,
  deleteDescription,
  deletePhotoDescriptionsByStorageKey,
} = DynamicPhotoSlice.actions;
export default DynamicPhotoSlice.reducer;
