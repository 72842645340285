// src/components/DynamicStoriesPage.js
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DOMPurify from "dompurify";
import { addStory, editStory, deleteStory } from "../store/DynamicStorySlice";
import logger from "../utils/logger";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";

// eslint-disable-next-line
const descriptionRegex = /^[a-zA-Z0-9\s.,!?'"(){}\[\]\/-]*$/;

const DynamicStoriesPage = ({ pageTitle, storyLabel, storageKey }) => {
  const [story, setStory] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const recognitionRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(null); // Track which story is playing
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const selectedVoice = useSelector(
    (state) => state.selectedVoice?.selectedVoice || null
  );

  const dispatch = useDispatch();
  const stories = useSelector(
    (state) => state.dynamicStories?.[storageKey]?.stories || []
  );

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (!descriptionRegex.test(inputValue)) {
      logger.error("Invalid characters in story.");
      return;
    }
    const sanitizedStory = DOMPurify.sanitize(inputValue);
    setStory(sanitizedStory);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (story.trim()) {
      if (isEditing !== null) {
        const storyId = stories[isEditing].id;
        dispatch(editStory({ storageKey, id: storyId, text: story }));
        logger.info("Edit story dispatched");
        setIsEditing(null);
      } else {
        dispatch(addStory({ storageKey, text: story }));
        logger.info("Add story dispatched");
      }
      setStory("");
      alert(`${storyLabel} saved.`);
    }
  };

  const handleEdit = (index) => {
    setStory(stories[index].text);
    setIsEditing(index);
  };

  const handleDelete = (index) => {
    if (
      window.confirm(
        `Are you sure you want to delete this ${storyLabel.toLowerCase()}?`
      )
    ) {
      const storyID = stories[index].id;
      dispatch(deleteStory({ storageKey, id: storyID }));
      logger.info("Delete story dispatched");
    }
  };

  const handleSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Speech recognition not supported in this browser. Please use Chrome."
      );
      return;
    }

    if (!recognitionRef.current) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = "en-US";

      recognition.onstart = () => setIsRecording(true);

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        logger.debug("Speech Transcript:", transcript);

        if (descriptionRegex.test(transcript)) {
          const sanitizedTranscript = DOMPurify.sanitize(transcript);
          const formattedTranscript =
            sanitizedTranscript.charAt(0).toUpperCase() +
            sanitizedTranscript.slice(1);
          setStory((prevStory) => `${prevStory} ${formattedTranscript}`);
        } else {
          logger.error("Invalid characters detected in speech transcript.");
        }
      };

      recognition.onerror = (event) => {
        logger.error("Speech recognition error", event);
        setIsRecording(false);
      };

      recognition.onend = () => {
        setIsRecording(false);
        if (story.trim()) {
          if (isEditing !== null) {
            dispatch({
              type: `${storageKey}/editStory`,
              payload: { id: stories[isEditing].id, text: story },
            });
            logger.info("Edit story dispatched");
            setIsEditing(null);
          } else {
            dispatch({ type: `${storageKey}/addStory`, payload: story });
            logger.info("Add story dispatched");
          }
          setStory("");
          alert(`Recording stopped and ${storyLabel.toLowerCase()} saved.`);
        }
      };

      recognitionRef.current = recognition;
    }

    if (isRecording) {
      recognitionRef.current.stop();
    } else {
      recognitionRef.current.start();
    }
  };

  const handlePlayPauseTTS = (text, index) => {
    if (isPlaying === index) {
      speechSynthesis.cancel();
      setIsPlaying(null);
    } else {
      if (speechSynthesisInstance) speechSynthesis.cancel(); // Stop any existing instance
      const utterance = new SpeechSynthesisUtterance(text);

      const availableVoices = speechSynthesis.getVoices();
      const fallbackVoice =
        availableVoices.find((voice) => voice.lang === "en-US") ||
        availableVoices[0]; // Default to first available voice

      utterance.voice =
        availableVoices.find((voice) => voice.name === selectedVoice) ||
        fallbackVoice;

      speechSynthesis.speak(utterance);
      setIsPlaying(index);

      utterance.onend = () => setIsPlaying(null);
      setSpeechSynthesisInstance(utterance);
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {pageTitle}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          id="story"
          label={storyLabel}
          multiline
          rows={6}
          variant="outlined"
          fullWidth
          value={story}
          onChange={handleChange}
          margin="normal"
          sx={{
            borderRadius: "10px",
            boxShadow: 3,
            fontFamily: "Verdana, sans-serif",
          }}
        />
        <IconButton
          color="default"
          onClick={handleSpeechRecognition}
          sx={{
            color: isRecording ? "red" : "inherit",
            marginRight: 1,
            width: 100,
            height: 100,
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
            "& svg": {
              fontSize: 48,
            },
          }}
          aria-label="record story"
        >
          {isRecording ? <StopCircleIcon /> : <MicIcon />}
        </IconButton>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#E29578",
            "&:hover": {
              backgroundColor: "#FFDDD2",
            },
            borderRadius: 10,
            fontFamily: "'Days One', sans-serif",
          }}
        >
          Submit
        </Button>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          {storyLabel}
        </Typography>
        {stories.length > 0 ? (
          stories.map((s, index) => (
            <Box
              key={index}
              sx={{
                mb: 2,
                p: 2,
                border: "1px solid #ccc",
                borderRadius: "10px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                aria-label="play-pause"
                onClick={() => handlePlayPauseTTS(s.text, index)}
                sx={{
                  marginRight: 1,
                  width: 100,
                  height: 100,
                  color: isPlaying === index ? "red" : "inherit",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  "& svg": {
                    fontSize: 48,
                  },
                }}
              >
                {isPlaying === index ? <PauseCircleIcon /> : <PlayCircleIcon />}
              </IconButton>

              <Typography
                variant="body1"
                sx={{ flexGrow: 1, fontFamily: "Verdana, sans-serif" }}
              >
                {s.text}
              </Typography>
              <IconButton
                aria-label="edit"
                onClick={() => handleEdit(index)}
                sx={{
                  marginRight: 1,
                  width: 100,
                  height: 100,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  "& svg": {
                    fontSize: 48,
                  },
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => handleDelete(index)}
                sx={{
                  marginRight: 1,
                  width: 100,
                  height: 100,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  "& svg": {
                    fontSize: 48,
                  },
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          ))
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No {storyLabel.toLowerCase()}s recorded yet.
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default DynamicStoriesPage;
