import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Placeholder from "../../Img/ProfilePlaceholder.jpg";
import axiosInstance from "../axiosConfig";
import { Button, Grid, Typography, Box } from "@mui/material";
import { format, isValid } from "date-fns";
import logger from "../../utils/logger";
import { saveSelectedFamilyVoice } from "../../store/familyVoiceSlice";

function FamilyProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { csrfToken } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  const familyId = user.familyName.familyId;
  const [profileImage, setProfileImage] = useState(user?.profileImage || "");
  const [voices, setVoices] = useState([]);
  const selectedVoiceObject = useSelector(
    (state) =>
      state.familyVoice?.familyVoice?.find(
        (voice) => voice.familyId === familyId
      ) || null
  );
  logger.debug(
    `Loaded selectedVoiceObject: ${JSON.stringify(selectedVoiceObject)}`
  );
  const [fallbackVoice, setFallbackVoice] = useState(null);
  const [localSelectedVoice, setLocalSelectedVoice] = useState(fallbackVoice);

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        const defaultVoice = availableVoices.find(
          (voice) => voice.lang === "en-US"
        );
        setVoices(availableVoices);
        setFallbackVoice(defaultVoice || availableVoices[0]); // Use the first available voice as a fallback
      }
    };

    loadVoices();

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  useEffect(() => {
    if (selectedVoiceObject) {
      setLocalSelectedVoice(selectedVoiceObject);
    } else {
      setLocalSelectedVoice(fallbackVoice);
    }
  }, [selectedVoiceObject, fallbackVoice]);

  const handleVoiceChange = (event) => {
    const selectedVoiceName = event.target.value;
    logger.debug(
      `Selected voice name in handleVoiceChange: ${JSON.stringify(
        selectedVoiceName
      )}`
    );
    const voice = voices.find((v) => v.name === selectedVoiceName);
    if (voice) {
      setLocalSelectedVoice(voice); // Set localSelectedVoice to the full voice object
      logger.debug(
        `Updated local selected voice in handleVoiceChange: ${JSON.stringify(
          voice
        )}`
      );
    } else {
      setLocalSelectedVoice(selectedVoiceName);
      logger.error("unable to find matching voice in handleVoiceChange");
    }

    playTestAudio(voice);
  };

  const handleSaveVoice = () => {
    if (!localSelectedVoice) {
      logger.error("No voice selected in handleSaveVoice");
      return;
    }
    const familyVoice = localSelectedVoice.name;
    logger.debug(
      `inside handleSaveVoice: ${JSON.stringify(localSelectedVoice)}`
    );

    dispatch(saveSelectedFamilyVoice({ familyId, familyVoice }));
    alert("Voice selection saved successfully");
  };

  const playTestAudio = (voice) => {
    const utterance = new SpeechSynthesisUtterance(`${voice.name}`);
    utterance.voice = voice;
    utterance.rate = 1; // Adjust rate as needed
    utterance.pitch = 1; // Adjust pitch as needed

    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utterance);
  };

  const handleEdit = () => {
    navigate("/familyeditprofile");
  };

  useEffect(() => {
    getProfilePhoto();
  }, []);

  const getProfilePhoto = async () => {
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com.au/lifeauth/profile-get",
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        const result = response.data[0];
        const base64ImageUrl = `data:${result.contentType};base64,${result.base64}`;
        setProfileImage(base64ImageUrl);
        logger.info("Profile photo fetched successfully");
      } else {
        logger.error("Failed to get image", response.data);
      }
    } catch (error) {
      logger.error("Error fetching profile photo:", error);
    }
  };

  const buttonStyle = {
    fontFamily: "'Days One', sans-serif",
    backgroundColor: "#E29578",
    color: "white",
    borderRadius: 10,
    width: "100%",
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "100%",
        margin: "auto",
        fontSize: "18px",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {user.familyName.name}'s Profile
      </Typography>

      <div
        style={{
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 2fr 3fr 3fr",
            fontFamily: "Verdana, sans-serif",
            padding: "10px",
            width: "100%",
          }}
        >
          <>
            <div style={{ padding: "4px 0" }}>
              <label
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Days One', sans-serif",
                }}
              >
                Relation:
              </label>{" "}
              {user.familyName.relation}
            </div>
            <div style={{ padding: "4px 0" }}>
              <label
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Days One', sans-serif",
                }}
              >
                Ph:
              </label>{" "}
              {user.familyName.phoneNumber}
            </div>
            <div style={{ padding: "4px 0" }}>
              <label
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Days One', sans-serif",
                }}
              >
                Email:
              </label>{" "}
              {user.familyName.email}
            </div>
            <div style={{ padding: "4px 0" }}>
              <label
                style={{
                  fontWeight: "bold",
                  fontFamily: "'Days One', sans-serif",
                }}
              >
                DOB:
              </label>{" "}
              {user.familyName.dob && isValid(new Date(user.familyName.dob))
                ? format(new Date(user.familyName.dob), "do 'of' MMMM yyyy")
                : ""}
            </div>
          </>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 5fr 3fr",
            fontFamily: "Verdana, sans-serif",
            padding: "10px",
            width: "100%",
          }}
        >
          <label
            style={{
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
              gridColumn: "1 / span 1",
            }}
          >
            Address:
          </label>{" "}
          <div style={{ gridColumn: "2 / span 1" }}>
            {user.familyName.postalAddress}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              gridColumn: "3 / span 1",
            }}
          >
            <select
              onChange={handleVoiceChange}
              value={localSelectedVoice ? localSelectedVoice.familyVoice : ""} // Use localSelectedVoice.name for the display
              style={{
                fontFamily: "Verdana, sans-serif",
                width: "90%", // Ensures the select box takes the full width
                padding: "10px", // Sets padding for the select box
                borderRadius: "10px", // Sets the same border radius
              }}
            >
              {voices.length > 0 ? (
                voices.map((voice) => (
                  <option key={voice.name} value={voice.name}>
                    {voice.name} ({voice.lang})
                  </option>
                ))
              ) : (
                <option>Loading available voices...</option>
              )}
            </select>
            <Button
              onClick={handleSaveVoice}
              style={{
                ...buttonStyle,
                width: "90%",
                padding: "10px",
              }}
            >
              Save Voice Choice
            </Button>
          </div>
        </div>
      </div>

      <Box display="flex" justifyContent="space-around" flexDirection="row">
        <img
          src={profileImage || Placeholder}
          alt="Profile"
          style={{
            marginTop: "10px",
            width: "40%",
            aspectRatio: "1 / 1",
            objectFit: "cover",
            borderRadius: "10px",
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="20px"
          width="40%"
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              fontFamily: "'Days One', sans-serif",
            }}
          >
            {user.name}'s Details:
          </Typography>
          <Box display="flex" flexDirection="column" gap="10px">
            {[
              { label: "Name", value: user.name },
              { label: "Email", value: user.email },
              {
                label: "Date of Birth",
                value: format(new Date(user.dob), "do 'of' MMMM yyyy"),
              },
              { label: "Phone Number", value: user.phoneNumber },
              { label: "Postal Address", value: user.address },
              { label: "Login Code", value: user.code },
            ].map((item) => (
              <Box
                key={item.label}
                display="flex"
                justifyContent="space-between"
                padding="10px"
                border="1px solid #ccc"
                borderRadius="8px"
                width="100%"
              >
                <label
                  style={{
                    fontWeight: "bold",
                    fontFamily: "'Days One', sans-serif",
                  }}
                >
                  {item.label}:
                </label>{" "}
                {item.value}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleEdit}
            style={buttonStyle}
          >
            Edit My Profile
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default FamilyProfile;
