import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSelector } from "react-redux";
import axiosInstance from "../axiosConfig";
import ShowVideoModal from "../ShowVideoModal";
import logger from "../../utils/logger";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PropTypes from "prop-types";

const FamilyVideosPage = ({ pageTitle, storyLabel, storageKey }) => {
  const [videos, setVideos] = useState([]);
  const error = useSelector((state) => state.dynamicVideos?.error);
  const videoDescriptions = useSelector(
    (state) => state.dynamicVideos?.[storageKey]?.dynamicVideos || []
  );
  const [descriptions, setDescriptions] = useState(videoDescriptions);
  const [refresh, setRefresh] = useState(true);
  const [fetching, setFetching] = useState(true);
  const { csrfToken } = useSelector((state) => state.auth);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(null); // Track which story is playing
  const [speechSynthesisInstance, setSpeechSynthesisInstance] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const familyId = user?.familyName?.familyId;
  const selectedVoiceObject = useSelector(
    (state) =>
      state.familyVoice?.familyVoice.find(
        (voice) => voice.familyId === familyId
      ) || null
  );
  const [fallbackVoice, setFallbackVoice] = useState(null);
  const [localSelectedVoice, setLocalSelectedVoice] = useState(fallbackVoice);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingFilename, setDownloadingFilename] = useState("");

  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = speechSynthesis.getVoices();
      if (availableVoices.length > 0) {
        const defaultVoice = availableVoices.find(
          (voice) => voice.lang === "en-US"
        );
        setFallbackVoice(defaultVoice || availableVoices[0]); // Use the first available voice as a fallback
      }
    };

    loadVoices();

    if (speechSynthesis.onvoiceschanged !== undefined) {
      speechSynthesis.onvoiceschanged = loadVoices;
    }
  }, []);

  useEffect(() => {
    if (selectedVoiceObject) {
      setLocalSelectedVoice(selectedVoiceObject);
    } else {
      setLocalSelectedVoice(fallbackVoice);
    }
  }, [selectedVoiceObject, fallbackVoice]);

  useEffect(() => {
    if (videos.length > 0 && videoDescriptions.length > 0) {
      logger.debug("Video descriptions:", videoDescriptions);
      const updatedDescriptions = videos.map((video) => {
        const matchingDescription = videoDescriptions.find(
          (desc) => String(desc.filename) === String(video.filename)
        );
        return matchingDescription ? matchingDescription.text : "";
      });
      logger.debug("Updated description:", updatedDescriptions);
      setDescriptions(updatedDescriptions);
      logger.debug("Videodescriptions:", videoDescriptions);
    }
    logger.debug("Videos:", videos);
    logger.debug("Video Descriptions:", videoDescriptions);
  }, [videos, videoDescriptions]);

  useEffect(() => {
    setVideos([]);
  }, [storageKey]);

  useEffect(() => {
    getThumbnails();
  }, [storageKey]);

  useEffect(() => {
    if (!refresh) return;
    getThumbnails();
    setRefresh(false);
  }, [refresh, csrfToken]);

  const getThumbnails = async () => {
    setFetching(true);
    try {
      const response = await axiosInstance.get(
        "https://memorydiaries.com.au/lifeData/videos/thumbnails",
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.debug("Response data:", response.data);
      logger.info("Thumbnails fetched successfully");
      if (Array.isArray(response.data)) {
        response.data.forEach((item) => {
          logger.info(`item.filename: ${item.filename}`);
        });

        const filteredData = response.data.filter(
          (item) => item.filename && item.filename.startsWith(`${storageKey}_`)
        );

        const updatedVideos = filteredData.map((video) => {
          logger.debug(`video name: ${video.filename}`);
          const modifiedStorageKey = video.filename
            ? video.filename.replace(new RegExp(`${storageKey}_`), "") // Remove everything before and including the first underscore
            : ""; // Default to an empty string or handle differently if filename is undefined

          return { ...video, filename: modifiedStorageKey }; // Update the storageKey field
        });

        setVideos(updatedVideos);
        setDescriptions(updatedVideos.map(() => ""));
      } else {
        logger.error("Unexpected response format:", response.data);
      }
    } catch (error) {
      logger.error("Error fetching thumbnails:", error);
    }
    setFetching(false);
  };

  const handleOpenVideoModal = async (streamingUrl) => {
    setShowVideo(true);
    try {
      const response = await axiosInstance.get(streamingUrl, {
        headers: {
          "csrf-token": csrfToken,
        },
        credentials: "include",
        responseType: "blob",
      });

      if (response.status === 200) {
        const videoObjectUrl = URL.createObjectURL(response.data);
        setSelectedVideo(videoObjectUrl);
      } else {
        logger.error("Failed to fetch video:", response.statusText);
      }
    } catch (error) {
      logger.error("Error fetching video:", error);
    }
  };

  const handleCloseVideoModal = () => {
    setSelectedVideo(null);
    setShowVideo(false);
  };

  const handleDownload = async (videoKey) => {
    setIsDownloading(true);
    setDownloadingFilename(videoKey);
    try {
      const videoName = `${storageKey}_${videoKey}`;
      const response = await axiosInstance.get(
        `https://memorydiaries.com.au/lifeData/videos/download/${videoName}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          responseType: "blob",
        }
      );

      const url = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      const modifiedName = videoName.replace(/^.*?_/, "");

      link.href = url;
      link.setAttribute("download", modifiedName); // Use the correct filename with the storage key and video key
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    } catch (error) {
      logger.error("Error downloading video:", error);
    }
    setIsDownloading(false);
    setDownloadingFilename("");
  };

  const handlePlayPauseTTS = (text, index) => {
    if (isPlaying === index) {
      speechSynthesis.cancel();
      setIsPlaying(null);
    } else {
      if (speechSynthesisInstance) speechSynthesis.cancel(); // Stop any existing instance
      const utterance = new SpeechSynthesisUtterance(text);

      const availableVoices = speechSynthesis.getVoices();
      const fallbackVoice =
        availableVoices.find((voice) => voice.lang === "en-US") ||
        availableVoices[0]; // Default to first available voice

      utterance.voice =
        availableVoices.find(
          (voice) => voice.name === localSelectedVoice.familyVoice
        ) || fallbackVoice;

      speechSynthesis.speak(utterance);
      setIsPlaying(index);

      utterance.onend = () => setIsPlaying(null);
      setSpeechSynthesisInstance(utterance);
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {user.name ? `${user.name}'s ${pageTitle}:` : `${pageTitle}`}
      </Typography>

      <Box sx={{ mt: 4 }}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          {storyLabel}
        </Typography>

        {fetching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <CircularProgress color="success" />
            <Typography
              variant="body1"
              style={{ fontFamily: "'Days One', sans-serif" }}
            >
              Loading videos...
            </Typography>
          </Box>
        ) : videos.length > 0 ? (
          <>
            <List>
              {videos.map((video, index) => {
                const streamingUrl = `https://memorydiaries.com/lifeData/videos/download/${storageKey}_${video.filename}`;

                return (
                  <ListItem
                    key={index}
                    sx={{
                      display: "grid",
                      alignItems: "center",
                      gridTemplateColumns: "30fr 6fr 25fr 6fr",
                      columnGap: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "20px",
                      marginBottom: "10px",
                      boxShadow: 3,
                    }}
                  >
                    <Box sx={{ gridColumn: "1 / span 1" }}>
                      <div
                        onClick={() => handleOpenVideoModal(streamingUrl)}
                        style={{ cursor: "pointer", marginRight: "50px" }}
                      >
                        <img
                          src={video.thumbnail}
                          alt={`Thumbnail for ${video.filename}`}
                          style={{
                            display: "block",
                            position: "relative",
                            width: "90%",
                            height: "100%",
                            objectFit: "cover",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Box>

                    {descriptions[index] && (
                      <Box
                        sx={{
                          gridColumn: "2 / span 1",
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "20px",
                          marginRight: "50px",
                        }}
                      >
                        <IconButton
                          aria-label="play-pause"
                          onClick={() =>
                            handlePlayPauseTTS(descriptions[index], index)
                          }
                          sx={{
                            display: "flex",
                            alignItems: "center", // Centers the item vertically
                            justifyContent: "center", // Centers the item horizontally (optional)
                            width: 100,
                            height: 100,
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.1)",
                            },
                            "& svg": {
                              fontSize: 48,
                            },
                          }}
                        >
                          {isPlaying === index ? (
                            <PauseCircleIcon />
                          ) : (
                            <PlayCircleIcon />
                          )}
                        </IconButton>
                      </Box>
                    )}

                    <Box
                      sx={{
                        gridColumn: "3 / span 1",
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "20px",
                        width: "100%",
                        marginRight: "50px",
                      }}
                    >
                      <ListItemText
                        primary={video.filename || `Video ${index + 1}`}
                        sx={{
                          marginBottom: "20px",
                          "& .MuiTypography-root": {
                            fontFamily: "'Days One', sans-serif",
                          },
                        }}
                      />

                      <>
                        <Typography
                          variant="body1"
                          style={{ fontFamily: "Verdana, sans-serif" }}
                        >
                          {descriptions[index]}
                        </Typography>
                      </>
                    </Box>

                    <Box
                      sx={{
                        gridColumn: "4 / span 1",
                        display: "flex",
                        marginLeft: "50px",
                        gap: 1,
                      }}
                    >
                      <IconButton
                        edge="end"
                        aria-label="download"
                        onClick={() => handleDownload(video.filename)}
                        sx={{
                          marginLeft: "16px",
                          marginRight: 1,
                          width: 100,
                          height: 100,
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                          },
                          "& svg": {
                            fontSize: 48,
                          },
                        }}
                      >
                        {isDownloading &&
                        downloadingFilename === video.filename ? (
                          <CircularProgress color="success" />
                        ) : (
                          <DownloadIcon />
                        )}
                      </IconButton>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : (
          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            No videos uploaded yet.
          </Typography>
        )}
      </Box>

      <ShowVideoModal
        showVideo={showVideo}
        selectedVideo={selectedVideo}
        handleCloseVideoModal={handleCloseVideoModal}
      />

      {error && (
        <Typography
          variant="body2"
          color="error"
          sx={{ mt: 2, fontFamily: "Verdana, sans-serif" }}
        >
          {error}
        </Typography>
      )}
    </Container>
  );
};

FamilyVideosPage.propTypes = {
  storyLabel: PropTypes.func.isRequired,
  pageTitle: PropTypes.func.isRequired,
  storageKey: PropTypes.func.isRequired,
};

export default FamilyVideosPage;
