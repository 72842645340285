import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Typography,
  Container,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import axiosInstance from "../axiosConfig";
import logger from "../../utils/logger";
import FileIcon from "../FileIconMap";

const FamilyDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const { csrfToken } = useSelector((state) => state.auth);
  const [fetching, setFetching] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    // Fetch photos from the backend
    const fetchDocuments = async () => {
      setFetching(true);
      try {
        const response = await axiosInstance.get(
          "https://memorydiaries.com.au/lifeData/documents/download",
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "csrf-token": csrfToken,
            },
            withCredentials: true,
          }
        );
        logger.debug("Response data:", response.data); // Log the response data
        setDocuments(response.data);
      } catch (error) {
        logger.error("Error fetching documents:", error);
      } finally {
        setFetching(false);
        logger.info("Finished fetching documents.");
      }
    };

    fetchDocuments();
  }, []);

  const handleDownload = (docKey) => {
    const doc = documents.find((doc) => doc.Key === docKey);
    if (doc) {
      const { base64, contentType, Key } = doc;

      if (base64 && contentType) {
        const binaryString = atob(base64);
        const byteArray = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }

        const blob = new Blob([byteArray], { type: contentType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", Key);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else {
        logger.error("Missing base64 data or contentType for the document.");
      }
    } else {
      logger.error("Document not found.");
    }
  };

  return (
    <Container style={{ marginTop: 20 }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontStyle: "italic",
          marginBottom: 20,
        }}
      >
        {user.name ? `${user.name}'s Documents:` : "Documents:"}
      </Typography>
      {fetching ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <CircularProgress color="success" />

          <Typography
            variant="body1"
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            Loading documents...
          </Typography>
        </Box>
      ) : documents.length > 0 ? (
        <List>
          {documents.map((doc, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "10px",
                padding: "10px",
                boxShadow: 3,
                mb: 2,
              }}
            >
              <IconButton
                aria-label="download"
                sx={{
                  marginRight: 1,
                  width: 80,
                  height: 80,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  "& svg": {
                    fontSize: 48,
                  },
                }}
              >
                <FileIcon fileType={{ name: doc.Key }} />
              </IconButton>

              <ListItemText
                primary={doc.Key || doc.Key}
                style={{ fontFamily: "Verdana, sans-serif" }}
              />
              <IconButton
                aria-label="download"
                onClick={() => handleDownload(doc.Key)}
              >
                <DownloadIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography
          variant="body1"
          style={{ fontFamily: "'Days One', sans-serif" }}
        >
          No documents uploaded yet.
        </Typography>
      )}
    </Container>
  );
};

export default FamilyDocuments;
