import React from "react";
import { Button, Container, Typography, Box, Link } from "@mui/material";

const TermsOfUse = ({ onClose }) => {
  const handleBack = () => {
    onClose();
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  const sections = [
    {
      section: "SECTION 1 - ACCEPTANCE OF TERMS",
      content: [
        "By visiting our site and/or subscribing to us, you engage in our “Service” and agree to be bound by these Terms of Use (“Terms”), including those additional terms and conditions referenced herein and/or available by hyperlink. These Terms of Use apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.",
        "If you do not agree to all the terms and conditions of this agreement, you may not access or use the website or services. If these Terms of Use are considered an offer, acceptance is expressly limited to these Terms of Use.",
      ],
    },
    {
      section: "SECTION 2 - CHANGES TO TERMS",
      content: [
        "We reserve the right to update, change, or replace any part of these Terms of Use at any time by posting updates and/or changes to our website. It is your responsibility to review this page periodically for any updates. Your continued use of or access to the website following the posting of any changes constitutes your acceptance of those changes.",
      ],
    },
    {
      section: "SECTION 3 - USER OBLIGATIONS",
      content: [
        "By agreeing to these Terms of Use, you represent that you are at least the age of majority in your state or province of residence, or that you have given us consent to allow your minor dependents to use this site.",
        "You agree not to use the service for any unlawful or unauthorized purpose, and you will not violate any laws in your jurisdiction while using the service (including but not limited to copyright laws).",
        "You agree not to transmit any worms, viruses, or any destructive code that may affect the functionality of our site or any related services.",
      ],
    },
    {
      section: "SECTION 4 - ACCOUNT SECURITY",
      content: [
        "You are responsible for maintaining the confidentiality of your account credentials and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account or password.",
        "You must notify us immediately of any breach of security or unauthorized use of your account. We are not liable for any loss or damage arising from your failure to protect your account information.",
      ],
    },
    {
      section: "SECTION 5 - PRIVACY POLICY",
      content: [
        `Your use of our website is also governed by our Privacy Policy. Please refer to our `,
        {
          linkText: "Privacy Policy",
          linkUrl: "/privacy",
        },
        " to understand how we collect, use, and protect your personal information.",
      ],
    },
    {
      section: "SECTION 6 - LIMITATION OF LIABILITY",
      content: [
        "Memory Diaries does not guarantee that your use of our services will be uninterrupted, timely, or error-free. We do not warrant that the results of using the service will be accurate or reliable.",
        "In no case shall Memory Diaries, its directors, officers, employees, affiliates, or agents be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages, including lost profits, revenue, data, or any other similar damages arising from your use of the service or products.",
      ],
    },
    {
      section: "SECTION 7 - TERMINATION",
      content: [
        "We may terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms of Use.",
        "Upon termination, your right to use the services will immediately cease. If you wish to terminate your account, you may discontinue use of the services.",
      ],
    },
    {
      section: "SECTION 8 - INTELLECTUAL PROPERTY",
      content: [
        "The content on this site, including text, graphics, logos, and images, is the property of Memory Diaries or its content suppliers and is protected by copyright, trademark, and other intellectual property laws.",
        "You may not reproduce, distribute, or create derivative works from any content on this site without explicit permission from Memory Diaries.",
      ],
    },
    {
      section: "SECTION 9 - INDEMNIFICATION",
      content: [
        "You agree to indemnify, defend, and hold harmless Memory Diaries and its affiliates, directors, officers, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorney fees, arising out of or connected with your use of our services or violation of these Terms of Use.",
      ],
    },
    {
      section: "SECTION 10 - GOVERNING LAW",
      content: [
        "These Terms of Use and any separate agreements whereby we provide you services shall be governed by and construed in accordance with the laws of the United States and Australia.",
      ],
    },
    {
      section: "SECTION 11 - ENTIRE AGREEMENT",
      content: [
        "These Terms of Use, together with any policies or operating rules posted by us on this site, constitute the entire agreement and understanding between you and us, governing your use of our services and superseding any prior agreements or communications.",
      ],
    },
    {
      section: "SECTION 12 - CONTACT INFORMATION",
      content: [
        "If you have any questions about these Terms of Use, please contact us at:",
        {
          text: "Email: ",
          linkText: "dr.dbrown@icloud.com",
          linkUrl: "mailto:dr.dbrown@icloud.com",
        },
        {
          text: "Address: PO BOX 4573, North Rocks, NSW, 2151, Australia",
        },
      ],
    },
  ];

  return (
    <Container maxWidth="md" style={{ padding: "20px", lineHeight: "1.6" }}>
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        style={{
          fontFamily: "'Days One', sans-serif",
          fontWeight: "bold",
          marginBottom: "20px",
        }}
      >
        Terms of Use
      </Typography>

      <Box mb={4}>
        <Typography variant="body1" paragraph style={{ fontFamily: "Verdana" }}>
          This website is operated by Memory Diaries. Throughout the site, the
          terms “we,” “us,” and “our” refer to Memory Diaries. By accessing or
          using this site, including all information, tools, and services
          available from this site, you agree to abide by the following terms
          and conditions. Please read them carefully.
        </Typography>
      </Box>

      {sections.map((section, index) => (
        <Box mb={4} key={index}>
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            style={{ fontFamily: "'Days One', sans-serif" }}
          >
            {section.section}
          </Typography>
          {section.content.map((paragraph, idx) => (
            <Typography
              variant="body1"
              paragraph
              key={idx}
              style={{ fontFamily: "Verdana" }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>
      ))}

      <Button
        onClick={handleBack}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "48%",
          fontFamily: "'Days One', sans-serif",
          marginBottom: 50,
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        aria-label="Go back"
      >
        Back
      </Button>
    </Container>
  );
};

export default TermsOfUse;
