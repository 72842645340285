import axiosInstance from "../components/axiosConfig";
import logger from "../utils/logger";

const saveFormDataMiddleware = (store) => (next) => async (action) => {
  if (shouldSkipAction(action)) {
    return next(action);
  }

  logger.debug("SaveFormDataMiddleware triggered for:", action.type);
  const result = next(action);

  if (shouldSaveState(action)) {
    await saveState(store, action);
  }

  return result;
};

const shouldSkipAction = (action) => {
  return (
    action.type.startsWith("data/clearAllData") ||
    action.type.endsWith("/clearData") ||
    action.type.startsWith("data/fetchBackendData") ||
    action.type.endsWith("/fulfilled") ||
    action.type.startsWith("data/fetchDataType") ||
    action.type.endsWith("/rejected") ||
    action.type.startsWith("family/fetchFamilyMembers") ||
    action.type.startsWith("paypal/checkPayPalPaymentStatus")
  );
};

const shouldSaveState = (action) => {
  // Map actions to specific data types
  const saveActions = {
    "pageConfig/addPageConfig": "pageConfig",
    "pageConfig/editPageConfig": "pageConfig",
    "pageConfig/deletePageConfig": "pageConfig",
    "dynamicStories/addStory": "dynamicStories",
    "dynamicStories/editStory": "dynamicStories",
    "dynamicStories/deleteStory": "dynamicStories",
    "dynamicStories/deleteStoriesByStorageKey": "dynamicStories",
    "dynamicPhotos/editDescription": "dynamicPhotos",
    "dynamicPhotos/deleteDescription": "dynamicPhotos",
    "dynamicPhotos/deletePhotoDescriptionsByStorageKey": "dynamicPhotos",
    "dynamicVideos/editDescription": "dynamicVideos",
    "dynamicVideos/deleteDescription": "dynamicVideos",
    "dynamicVideos/deleteVideoDescriptionsByStorageKey": "dynamicVideos",
    "selectedVoice/saveSelectedVoice": "selectedVoice",
    "familyVoice/saveSelectedFamilyVoice": "familyVoice",
    "stripe/updatePayPalSubscriptionId": "stripe",
  };

  return saveActions[action.type] !== undefined;
};

const saveState = async (store, action) => {
  const apiUrlBase = `https://memorydiaries.com/lifeData/saveData`;
  const currentState = store.getState();
  const saveActions = {
    "pageConfig/addPageConfig": "pageConfig",
    "pageConfig/editPageConfig": "pageConfig",
    "pageConfig/deletePageConfig": "pageConfig",
    "dynamicStories/addStory": "dynamicStories",
    "dynamicStories/editStory": "dynamicStories",
    "dynamicStories/deleteStory": "dynamicStories",
    "dynamicStories/deleteStoriesByStorageKey": "dynamicStories",
    "dynamicPhotos/editDescription": "dynamicPhotos",
    "dynamicPhotos/deleteDescription": "dynamicPhotos",
    "dynamicPhotos/deletePhotoDescriptionsByStorageKey": "dynamicPhotos",
    "dynamicVideos/editDescription": "dynamicVideos",
    "dynamicVideos/deleteDescription": "dynamicVideos",
    "dynamicVideos/deleteVideoDescriptionsByStorageKey": "dynamicVideos",
    "selectedVoice/saveSelectedVoice": "selectedVoice",
    "familyVoice/saveSelectedFamilyVoice": "familyVoice",
    "stripe/updatePayPalSubscriptionId": "stripe",
  };

  const dataType = saveActions[action.type];
  const dataToSave = currentState[dataType];
  logger.debug(`Data type: ${dataType}, Data: ${JSON.stringify(dataToSave)}`);

  if (!dataType || !dataToSave) {
    logger.warn(`No data to save for action: ${action.type}`);
    return;
  }

  try {
    const apiUrl = `${apiUrlBase}/${dataType}`;
    const { csrfToken } = currentState.auth;
    logger.debug(`CSRF Token before making request: ${csrfToken}`);

    const response = await axiosInstance.post(
      apiUrl,
      { data: dataToSave },
      {
        headers: {
          "csrf-token": csrfToken,
        },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      logger.info(`${dataType} data saved successfully`);
    } else {
      throw new Error(`Failed to save ${dataType}`);
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || error.message || "Unknown error";
    logger.error(`Error saving ${dataType} data:`, errorMessage);
  }
};

export default saveFormDataMiddleware;
