import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import logger from "../utils/logger";

// Clear all data
export const clearAllData = createAsyncThunk(
  "data/clearAllData",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(clearData());
    return true; // Indicate success
  }
);

// Fetch all backend data (entire state)
export const fetchBackendData = createAsyncThunk(
  "data/fetchBackendData",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.get(
        `https://memorydiaries.com.au/lifeData/getState`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      logger.debug("Fetched backend state:", response.data);
      return response.data; // Return full state object
    } catch (error) {
      logger.error("Failed to fetch backend state:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Fetch specific data type from backend
export const fetchDataType = createAsyncThunk(
  "data/fetchDataType",
  async (dataType, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.get(
        `https://memorydiaries.com.au/lifeData/getData/${dataType}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      logger.debug(`Fetched ${dataType} data:`, response.data);
      return { dataType, data: response.data.data || null };
    } catch (error) {
      logger.error(`Failed to fetch ${dataType} data:`, error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Delete all backend data
export const deleteBackendData = createAsyncThunk(
  "data/deleteBackendData",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/deleteState`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      logger.debug("Deleted all backend data:", response.data);
      return response.data;
    } catch (error) {
      logger.error("Failed to delete all backend data:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Delete specific data type from backend
export const deleteDataType = createAsyncThunk(
  "data/deleteDataType",
  async (dataType, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/deleteData/${dataType}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );

      logger.debug(`Deleted ${dataType} data:`, response.data);
      return { dataType };
    } catch (error) {
      logger.error(`Failed to delete ${dataType} data:`, error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Initial state
const initialState = {
  data: {}, // Stores all data types
  status: "idle",
  error: null,
};

// Redux slice
const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    clearData: (state) => {
      return { ...initialState }; // Reset state to initial state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackendData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload || {};
        logger.debug(
          `Data fetch state status: ${
            state.status
          } with state.data: ${JSON.stringify(state.data)}`
        );
        state.error = null;
      })
      .addCase(fetchBackendData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Unknown error";
        alert("Failed to fetch user data");
        logger.error("Fetch failed:", action.payload);
      })
      .addCase(fetchDataType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDataType.fulfilled, (state, action) => {
        const { dataType, data } = action.payload;
        state.status = "succeeded";
        logger.debug(`Fetched and updated ${dataType} data in state`);
        state.data[dataType] = data;
      })
      .addCase(fetchDataType.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Unknown error";
        alert("Failed to fetch specific data type");
        logger.error("Fetch failed:", action.payload);
      })
      .addCase(deleteBackendData.fulfilled, () => {
        return { ...initialState }; // Reset state to initial state
      })
      .addCase(deleteBackendData.rejected, (state, action) => {
        state.error = action.payload || "Unknown error";
        logger.error("Failed to delete all backend data:", action.payload);
      })
      .addCase(deleteDataType.fulfilled, (state, action) => {
        const { dataType } = action.payload;
        delete state.data[dataType]; // Remove the specific data type from state
        logger.debug(`Deleted ${dataType} data from state`);
      })
      .addCase(deleteDataType.rejected, (state, action) => {
        state.error = action.payload || "Unknown error";
        logger.error("Failed to delete specific data type:", action.payload);
      })
      .addCase(clearAllData.fulfilled, () => {
        return { ...initialState }; // Reset state to initial state
      });
  },
});

export const { clearData } = dataSlice.actions;

export default dataSlice.reducer;
