import React, { useState } from "react";
import "./FrequentlyAskedQuestion.css";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

const faqs = [
  {
    question: "What is Memory Diaries?",
    answer:
      "Memory Diaries is a platform to record and share memories with family and friends. You can share your life's greatest stories and wisdom. Ensure that your legacy of your memories lives on with your family.",
  },
  {
    question: "What are the benefits Memory Diaries?",
    answer:
      "Memory Diaries improves genuine connections with family and friends. It also preserves your memories and stories for future generations.",
  },
  {
    question: "How is Memory Diaries different to social media platforms?",
    answer:
      "The focus is on sharing personal memories with closest family in a secure and private environment. ",
  },
  {
    question: "How can I record my favorite stories?",
    answer:
      "You can record your life story by typing or recoding audio to convert to text. ",
  },
  {
    question: "Who can I share my story with?",
    answer:
      "You can share your stories with your family members and friends who are registered on the platform. To login they will need to be added as family members and need their email and the 6 character code",
  },
  {
    question: "Where do I find the 6 digit code?",
    answer: "On the Profile page",
  },
  {
    question: "Can I save documents, photos and videos?",
    answer:
      "You can save documents, save photos or take photos and save videos or record videos to share.",
  },
  {
    question: "What format are videos saved in?",
    answer:
      "Currently we convert all videos to mp4 format as this is optimised for web and mobile.",
  },
  {
    question:
      "What are the benefits of your website for people with broken families?",
    answer:
      "In modern society, seperation and divorce are unfortunately increasingly common. Grandparents can lose access to seeing their grandkids. Memory Diaries allows you to save memories and stories so your kids and grandkids will not forget your legacy. It gives you the tools to bridge family connections through time.",
  },
  {
    question:
      "What are the benefits of your website for people who could develop dementia?",
    answer:
      "In dementia, where there is loss of memory, reminding people of their memories and familiar events is an important part of treating the condition. Recalling meories is an important part of non-pharmacological treatment of dementia and slowing dementia progression",
  },
  {
    question:
      "Is it a substitute for having my own backups of photos and videos?",
    answer:
      "No, it is not a substitute for having your own backups. It is a platform to share and store your memories.",
  },
  {
    question: "Can I edit my recordings?",
    answer:
      "Yes! After recording, you'll have the option to edit your story before sharing it.",
  },
  {
    question: "What if I have trouble recording my story?",
    answer:
      "If you encounter any issues, please contact our support team by email for assistance.",
  },
  {
    question: "Are Payments Secure?",
    answer: "Yes, all payments are secure and encrypted through PayPal.",
  },
  {
    question: "Is it true family members can login for free?",
    answer:
      "Yes, family members do not need to pay. They will be able to access the platform for free.",
  },
  {
    question: "How long is the free trial?",
    answer:
      "The free trial is currently for 12 months for first users. After this users will be offered the oppurtinity to upgrade to a standard plan. If user no longer wish to use the Memory Diaries, their data may be deleted after a 30 day period. This policy is currently under review",
  },
  {
    question: "How long are my stories retained for?",
    answer:
      "We retain your stories for 5 years after the last login by you or family members. If you no longer wish to use the Memory Diaries, your data may be deleted after a 30 day period. This policy is currently under review",
  },
  {
    question: "What are the subscription plans after the free trial?",
    answer:
      "We offer plans ranging from $12.99 per month, $55.99 for 6 months, or $99.99 per year. This includes recording, storage, and sharing of your memories with selected family members. These prices are in US Dollars.",
  },
  {
    question: "What are your companies core values?",
    answer: "Integrity, Respect and Continuous Improvement",
  },
];

const FrequentlyAskedQuestions = ({ onClose }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleBack = () => {
    onClose();
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    borderRadius: 10,
  };

  const buttonHoverStyle = {
    backgroundColor: "#FFDDD2",
  };

  return (
    <div className="faq-container">
      <h1 style={{ fontFamily: "'Days One', sans-serif" }}>
        Frequently Asked Questions
      </h1>
      <div className="faq-list">
        {faqs.map((faq) => (
          <div key={faq.question} className="faq-item">
            <button
              className={`faq-question ${
                openIndex === faq.question ? "open" : ""
              }`}
              onClick={() => toggleFAQ(faq.question)}
              style={{ fontFamily: "Verdana, sans-serif" }}
            >
              {faq.question}
              <span
                className={`faq-toggle ${
                  openIndex === faq.question ? "open" : ""
                }`}
              >
                {openIndex === faq.question ? "▲" : "▼"}{" "}
                {/* Add arrows instead of "+" and "-" */}
              </span>
            </button>
            {openIndex === faq.question && (
              <div
                className="faq-answer"
                style={{ fontFamily: "Verdana, sans-serif" }}
              >
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
      <Button
        onClick={handleBack}
        variant="contained"
        style={{
          ...buttonStyle,
          width: "48%",
          fontFamily: "'Days One', sans-serif",
        }}
        onMouseOver={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onMouseOut={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        onFocus={(e) =>
          (e.currentTarget.style.backgroundColor =
            buttonHoverStyle.backgroundColor)
        }
        onBlur={(e) =>
          (e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor)
        }
        aria-label="Go back"
      >
        Back
      </Button>
    </div>
  );
};

FrequentlyAskedQuestions.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FrequentlyAskedQuestions;
