import React, { useState, useEffect } from "react";
import {
  Dialog,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { createCustomerAndSubscription } from "../store/stripeSlice";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import logger from "../utils/logger";
import axiosInstance from "./axiosConfig";

const Checkout = ({
  open,
  onClose,
  onCheckoutSuccess,
  onCheckoutFailure,
  customerEmail,
}) => {
  const [plan, setPlan] = useState("P-Free-Plan");
  const currency = "AUD";
  const dispatch = useDispatch();
  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [discountCode, setDiscountCode] = useState("");
  const [discountApplied, setDiscountApplied] = useState(false);
  const freeCode = process.env.REACT_APP_FREE_CODE;

  useEffect(() => {
    logger.debug("Current plan:", plan);
  }, [plan]);

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const applyDiscountCode = () => {
    // Simple example of validating discount code
    if (discountCode === freeCode) {
      setDiscountApplied(true);
      logger.info("Discount code applied successfully");
      handleSubscription();
    } else {
      setDiscountApplied(false);
      logger.error("Invalid discount code");
    }
  };

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
  };

  const handleSubscription = async (data = {}, payerID = "") => {
    try {
      const isFreePlan = discountApplied;
      if (!discountApplied) {
        handleCheckout();
      }
      const planId = isFreePlan ? "P-Free-Plan" : plan;
      const paymentId = isFreePlan
        ? "Free-paymentId"
        : data.subscriptionID || "Default-paymentId";
      const payerId = isFreePlan
        ? "Free-payerId"
        : payerID || "Default-payerId";

      const result = await dispatch(
        createCustomerAndSubscription({
          plan_id: planId,
          currency,
          payerId,
          paymentId,
          customerEmail: customerEmail,
        })
      ).unwrap();

      logger.info("Subscription created successfully", result);
      if (onCheckoutSuccess) onCheckoutSuccess(result);
    } catch (err) {
      logger.error(
        "Failed to create customer and subscription. Please try again."
      );
      if (onCheckoutFailure) onCheckoutFailure(err.message);
    }
  };

  const buttonStyle = {
    fontSize: "18px",
    padding: "10px 20px",
    color: "white",
    backgroundColor: "#E29578",
    border: "none",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginRight: 10,
    fontFamily: "'Days One', sans-serif",
  };

  logger.debug("Client ID:", clientId);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  const handleCheckout = async () => {
    const affiliateId = getCookie("affiliate_id");
    try {
      const response = await axiosInstance.post(
        `https://memorydiaries.com.au/lifeauth/checkout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
          body: JSON.stringify({ affiliate_id: affiliateId }), // Pass affiliate ID to backend
        }
      );
      const result = await response.json();
      if (response.ok) {
        logger.info("Checkout successful:", result);
      } else {
        logger.error("Checkout error:", result.error);
      }
    } catch (err) {
      logger.error("Network error:", err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ padding: 20 }}>
        <h1 style={{ fontFamily: "'Days One', sans-serif" }}>
          Choose Your Subscription
        </h1>
        <h2 style={{ fontFamily: "Verdana, sans-serif" }}>Free 7 Day Trial</h2>
        <h3 style={{ fontFamily: "Verdana, sans-serif" }}>
          Your card will not be charged until after the 7-day trial period. You
          can cancel a subscription at any time.
        </h3>

        <RadioGroup
          value={plan}
          onChange={handlePlanChange}
          style={{ fontFamily: "Verdana, sans-serif" }}
        >
          <FormControlLabel
            value="P-8Y912295YU738990FM44IFPQ"
            control={<Radio />}
            label="Monthly Subscription - $19.99 USD"
          />
          <FormControlLabel
            value="P-3HX201624G573405AM44IGYQ"
            control={<Radio />}
            label="Six-Monthly Subscription - $79.00 USD"
          />
          <FormControlLabel
            value="P-51U31049DF7518523M44IH3Y"
            control={<Radio />}
            label="Yearly Subscription - $150.00 USD"
          />
        </RadioGroup>

        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <h2 style={{ fontFamily: "Verdana, sans-serif" }}>Payment details</h2>

          {!discountApplied && (
            <PayPalScriptProvider
              options={{
                "client-id": clientId,
                intent: "subscription",
                currency,
                vault: true,
              }}
            >
              <PayPalButtons
                createSubscription={(data, actions) => {
                  return actions.subscription.create({
                    plan_id: plan,
                  });
                }}
                onApprove={(data, actions) => {
                  return actions.subscription
                    .get(data.subscriptionID)
                    .then((details) => {
                      const payerID = details.subscriber.payer_id;
                      console.log(`Payer ID: ${payerID}`);
                      logger.debug(
                        `Subscription approved with ID: ${
                          data.subscriptionID
                        } and data ${JSON.stringify(data)}`
                      );
                      handleSubscription(data, payerID);
                    });
                }}
              />
              {loading && <div>Loading...</div>}
            </PayPalScriptProvider>
          )}
          <div style={{ marginTop: 20, fontSize: 10 }}>
            <TextField
              label="Enter Discount Code"
              value={discountCode}
              onChange={handleDiscountCodeChange}
              variant="outlined"
              size="small"
              style={{ marginRight: 10 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={applyDiscountCode}
              style={buttonStyle}
            >
              Apply
            </Button>
            {discountApplied && (
              <div style={{ marginTop: 10, color: "green" }}>
                Free plan applied.
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default Checkout;
