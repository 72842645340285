// src/store/cloudObjectSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../components/axiosConfig";
import { fetchBackendData, clearAllData } from "./fetchBackendData";
import logger from "../utils/logger";

const initialState = {
  dynamicVideos: [],
  loading: false,
  error: null,
};

export const saveVideo = createAsyncThunk(
  "videos/saveVideo",
  async (modifiedFile, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    logger.debug("Uploading video:", modifiedFile);
    const formData = new FormData();
    formData.append("file", modifiedFile);
    try {
      const response = await axiosInstance.post(
        "https://memorydiaries.com.au/lifeData/videos/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "csrf-token": csrfToken,
          },
          withCredentials: true,
          timeout: 31200000,
        }
      );
      logger.debug("Upload response:", response.data);
      return response.data;
    } catch (error) {
      logger.error("Upload error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  "videos/deleteVideo",
  async ({ fileKey }, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    logger.debug("Deleting video:", fileKey);
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/videos/delete/${fileKey}`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.debug("Deletion response:", response.data);
      return response.data;
    } catch (error) {
      logger.error("Deletion error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteVideosByStorageKey = createAsyncThunk(
  "videos/deleteVideosByStorageKey",
  async (storageKey, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    logger.debug("Deleting all videos for storageKey:", storageKey);
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/videos/deleteAllByStorageKey`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          data: { storageKey },
          withCredentials: true,
        }
      );
      logger.debug("Deletion response:", response.data);
      return response.data;
    } catch (error) {
      logger.error("Deletion error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const deleteAllVideos = createAsyncThunk(
  "videos/deleteAllVideos",
  async (_, { rejectWithValue, getState }) => {
    const { csrfToken } = getState().auth;
    logger.info("Deleting all videos");
    try {
      const response = await axiosInstance.delete(
        `https://memorydiaries.com.au/lifeData/videos/deleteAllVideos`,
        {
          headers: {
            "csrf-token": csrfToken,
          },
          withCredentials: true,
        }
      );
      logger.debug("Deletion response:", response.data);
      return response.data;
    } catch (error) {
      logger.error("Deletion error:", error);
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const DynamicVideoSlice = createSlice({
  name: "dynamicVideos",
  initialState,
  reducers: {
    editDescription: (state, action) => {
      const { storageKey, filename, text } = action.payload; // Include storageKey in the payload
      logger.debug(
        `Recieved editDescription action with key: ${filename} and text: ${text}`
      );
      const description = state[storageKey]?.dynamicVideos?.find(
        (desc) => desc.filename === filename
      );
      if (description) {
        description.text = text; // Update existing description
      } else {
        logger.debug(
          `No existing description, new description with key: ${filename} and text: ${text}`
        );
        if (!state[storageKey]) {
          state[storageKey] = { dynamicVideos: [] };
        }
        state[storageKey].dynamicVideos.push({ filename: filename, text }); // Add new description
      }
      logger.debug(
        "Updated redux videoDescriptions:",
        state[storageKey]?.dynamicVideos
      );
    },
    deleteDescription: (state, action) => {
      const { storageKey, filename } = action.payload; // Include storageKey in the payload
      if (state[storageKey]) {
        state[storageKey].dynamicVideos = state[
          storageKey
        ].videoDescriptions.filter((desc) => desc.filename !== filename);
        logger.debug(
          "Updated redux videoDescriptions after delete:",
          state[storageKey]?.dynamicVideos
        );
      }
    },
    deleteVideoDescriptionsByStorageKey: (state, action) => {
      const { storageKey } = action.payload;
      logger.debug("StorageKey:", storageKey);
      logger.debug("Available keys in dynamicVideos:", Object.keys(state));

      if (!state[storageKey]) {
        logger.warn(`Key "${storageKey}" not found in dynamicVideos`);
        return state; // No changes
      }

      if (!Object.hasOwn(state, storageKey)) {
        logger.warn(`Key "${storageKey}" not found in dynamicVideos.`);
        return state; // Return the state unchanged
      }
      const updatedState = { ...state };
      delete updatedState[storageKey];

      return updatedState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteVideo.fulfilled, (state, action) => {
        state.loading = false;
        const { storageKey, fileKey } = action.payload;
        if (state[storageKey]) {
          state[storageKey].dynamicVideos = state[
            storageKey
          ].dynamicVideos.filter((vid) => vid.filename !== fileKey);
          logger.info("Video deleted successfully");
        }
      })
      .addCase(deleteVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteVideosByStorageKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteVideosByStorageKey.fulfilled, (state, action) => {
        state.loading = false;
        const { storageKey } = action.payload;
        delete state[storageKey];
        logger.info("All videos deleted successfully");
      })
      .addCase(deleteVideosByStorageKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteAllVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAllVideos.fulfilled, (state) => {
        state.loading = false;
        return { ...initialState };
      })
      .addCase(deleteAllVideos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBackendData.fulfilled, (state, action) => {
        const fetchedVideos = action.payload?.data?.dynamicVideos || {};
        Object.keys(fetchedVideos).forEach((key) => {
          Object.keys(fetchedVideos).forEach((key) => {
            if (Array.isArray(state[key])) {
              state[key] = [...state[key], ...fetchedVideos[key]];
            } else {
              state[key] = fetchedVideos[key];
            }
          });
        });
      })
      .addCase(clearAllData.fulfilled, (state) => {
        return { ...initialState };
      });
  },
});

export const {
  editDescription,
  deleteDescription,
  deleteVideoDescriptionsByStorageKey,
} = DynamicVideoSlice.actions;
export default DynamicVideoSlice.reducer;
